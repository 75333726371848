import { bus } from "../main";

import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import { Notification } from "element-ui";
import * as mexp from "math-expression-evaluator";
var fieldsList = require("../components/templates/fields.json");
import { postAPICall } from "../helpers/httpHelper";
import { fetchGlobalVariables } from "../repo/globalVariables";
import { fetchEntitiesByPagination } from "../repo/entityRepo";
import { fetchEntityViews } from "../repo/entityViewsRepo";
import { getAllCompanyTemplatesByPagination } from "../repo/templatesRepo";
import { fetchFormBuildersByPagination } from "@/repo/formbuilderDetails";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import { fetchAllIcons } from "@/repo/iconsRepo";
export default {
  data() {
    return {
      weekDayMap: {
        MON: "Monday",
        TUE: "Tuesday",
        WED: "Wednesday",
        THU: "Thursday",
        FRI: "Friday",
        SAT: "Saturday",
        SUN: "Sunday",
      },
      labelsData: {},
      executedNotifications: {},
      rulesData: {},
    };
  },
  mixins: [FieldsFormatHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getUserTypeList",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("companyTemplates", ["getTemplateDataTempVariable"]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getAllFormBuildersData",
    ]),
    ...mapGetters("entities", ["getEntityRecordsForTable"]),
    ...mapGetters("menuManagementV2", ["getMenu", "getEntityMenu"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    getElementStyleFromField() {
      return (data) => {
        let borderStyle = "";
        if (data.styles) {
          let type = data.styles.border_type
            ? data.styles.border_type
            : "solid";
          let size = data.styles.border_size
            ? data.styles.border_size + "px"
            : "0px";
          let color = data.styles.border_color ? data.styles.border_color : "";

          borderStyle = "border:" + type + " " + " " + size + " " + color;

          borderStyle += ";";
          borderStyle += data.styles.background
            ? `background: ${data.styles.background}`
            : "";
        }
        return borderStyle;
      };
    },
    getCurrentDate() {
      const currentDate = moment().startOf("day");
      const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
      const adjustedDate = currentDate.add(timeZoneOffsetMinutes, "minutes");
      return adjustedDate.format().split("+")[0] + ".000Z";
    },
  },
  methods: {
    openEntityDataScreen(
      entity_id,
      dataId = "",
      action = "",
      from = "fromEntityView"
    ) {
      let query = this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.isApplicationUserSide) {
        let urlPath = "/ap/entity-execute";
        if (action == "VIEW") {
          urlPath = "/ap/entity-view";
        }
        let sQuery = {
          layout: viewType,
          type: "s7aLF3NnAQg=",
          [from]: true,
          returnTo: this.$route.fullPath,
        };
        if (dataId) {
          sQuery["dataId"] = dataId;
        }
        this.$router.push({
          path: `${urlPath}/${entity_id}`,
          query: {
            ...query,
            ...sQuery,
          },
        });
      } else {
        let UrlPathAdd = "";
        let urlPath = "/entity/edit";
        if (action == "VIEW") {
          urlPath = "/entity/view";
        }
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}${urlPath}/${entity_id}`;
        } else {
          UrlPathAdd = `${urlPath}/${entity_id}`;
        }
        let sQuery = {
          layout: viewType,
          [from]: true,
          returnTo: this.$route.fullPath,
        };

        if (dataId) {
          sQuery["dataId"] = dataId;
        }

        this.$router.push({
          path: UrlPathAdd,
          query: { ...query, ...sQuery },
        });
      }
    },
    // getFormattedValue(data, inputType) {
    //   switch (inputType) {
    //     case 'MULTI_SELECT':
    //       return Array.isArray(data) ? data : [data];
    //     default:
    //       if (Array.isArray(data)) {
    //         return data.join(', ');
    //       } else if (typeof data === 'string') {
    //         return data;
    //       } else {
    //         console.log(`Unsupported data type received for inputType '${inputType}':`, data);
    //         return data;
    //       }
    //   }
    // },
    getFormattedValue(data, operation, inputType) {
      if (operation === "keyword_extraction" && inputType !== "MULTI_SELECT") {
        if (Array.isArray(data)) {
          return data.join(",");
        } else {
          return data;
        }
      }
      return data;
    },
    getProfilePicture(row) {
      if (
        row?.contact_id &&
        this.entityUserInfoData?.[row.contact_id]?.avatar
      ) {
        return this.entityUserInfoData[row.contact_id].avatar;
      } else {
        return null;
      }
    },
    isSystemColumn(field) {
      return (
        [
          "created_at",
          "updated_at",
          "created_by",
          "updated_by",
          "profile_picture_with_name",
          "status",
        ].includes(field?.template_id) &&
        [
          "created_at",
          "updated_at",
          "created_by",
          "updated_by",
          "profile_picture_with_name",
          "status",
        ].includes(field?.id)
      );
    },
    async mapEntityDataWithUserInfo(entityData) {
      let contact_ids = [],
        user_ids = [];
      entityData.forEach((data) => {
        if (data?.contact_id) {
          contact_ids.push(data.contact_id);
        }
        if (data?.updated_by) {
          user_ids.push(data.updated_by);
        }
        if (data?.created_by) {
          user_ids.push(data.created_by);
        }
      });
      return this.fetchEntityUsersInfo(
        [...new Set(contact_ids)],
        [...new Set(user_ids)]
      );
    },
    async fetchEntityUsersInfo(contact_ids, user_ids) {
      try {
        const response = await postAPICall(
          "POST",
          "/entities-data/users-info",
          {
            contact_ids,
            user_ids,
          }
        );
        return response.data;
      } catch (e) {
        console.log("", e);
        return {};
      }
    },
    validExpression(field) {
      let exp = "";
      let lastField = "";
      let err = false;

      if (
        field &&
        field.selected_fields &&
        field.selected_fields[0] &&
        (field.selected_fields[0].input_type === "DATE_TIME_RANGE" ||
          field.selected_fields[0].input_type === "DATE_RANGE")
      ) {
        return true;
      } else {
        let selectedFieldsLength = field.selected_fields.length;
        if (selectedFieldsLength && selectedFieldsLength > 2) {
          field.selected_fields.map((el) => {
            if (el.type == "OPERATOR") {
              exp += el.operator + " ";
              if (lastField != "operator") {
                lastField = "operator";
              } else {
                err = true;
              }
            } else if (el.type == "PARENTHESES") {
              exp += el.parentheses + " ";
              lastField = "";
            } else {
              exp += Math.floor(Math.random() * Math.floor(9)) + " ";
              if (lastField != "number") {
                lastField = "number";
              } else {
                err = true;
              }
            }
            return;
          });
          try {
            if (err) {
              return false;
            } else {
              mexp.eval(exp);
              return true;
            }
          } catch (e) {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    disableFieldButton(field) {
      if (
        [
          "PARAGRAPH",
          "SINGLE_LINE_CONTENT",
          "HORIZONTAL_LINE",
          "DIVISION",
        ].indexOf(field.input_type) > -1
      ) {
        return !field.content;
      }
      if (["MULTI_LINE_TEXT"].indexOf(field.input_type) > -1) {
        if (field.validations.rows < 3) {
          return false;
        }
        return !field.validations.rows || !field.label;
      }

      if (
        field.input_type === "SELECT" ||
        field.input_type === "MULTI_SELECT"
      ) {
        if (field.is_global_variable) {
          return !field.label || !field.global_variable_id;
        } else {
          return (
            (field.options &&
              field.options.length == 0 &&
              !field.allow_to_create) ||
            ((field?.options || []).some((opt) => opt.trim() === "") &&
              !field.allow_to_create) ||
            !field.label
          );
        }
      }
      if (field.input_type === "HTML_CONTENT") {
        return !field.content;
      }

      if (
        field.input_type === "CHECKBOX_GROUP" ||
        field.input_type === "RADIO_BUTTON"
      ) {
        if (field.is_global_variable) {
          return !field.label;
        } else {
          return (field.options && field.options.length == "0") || !field.label;
        }
      }

      if (field.input_type === "CURRENCY") {
        {
          if (!field.label) {
            return true;
          }
        }
        return !field.validations.currency;
      }
      if (field.input_type === "MULTI_LINE_TEXT") {
        if (field.validations.rows < 3) {
          return false;
        }

        return !field.validations.rows || !field.label;
      }
      if (field.input_type === "IMAGE") {
        return !field.label;
      }
      if (field.input_type === "AUTHORIZED_SIGNATURE") {
        return !field.authorized_signature;
      }
      if (field.input_type === "FORMULA") {
        let hasCurrencyField = false;
        let hasDateField = false;
        let hasTimeField = false;

        for (const selectedField of field.selected_fields) {
          if (selectedField.input_type === "CURRENCY") {
            hasCurrencyField = true;
          } else if (selectedField.input_type === "DATE") {
            hasDateField = true;
          } else if (selectedField.input_type === "TIME") {
            hasTimeField = true;
          }
        }

        return (
          !this.validExpression(field) ||
          (!field.time_result_type && hasTimeField) ||
          (!field.validations.currency && hasCurrencyField) ||
          (!field.date_result_type && hasDateField) ||
          !field.label
        );
      }

      if (field.input_type === "AUTO_INCREMENT_NUMBER") {
        return (
          (!field.auto_increment_prefix &&
            !field.auto_increment_starting_number) ||
          !field.label
        );
      }
      if (field.input_type === "PAY_BUTTON") {
        return (
          (field.validations.paymentSystems &&
            !field.validations.paymentSystems.length) ||
          (field.validations.payModes && !field.validations.payModes.length) ||
          field.validations.currency == undefined ||
          field.validations.entityVariable_info == undefined
        );
      }

      if (field.input_type === "ACTION_BUTTON") {
        if (field.label) {
          if (field?.actions && field.actions.length) {
            let emptyField = field.actions.find((action) => {
              if (!action.action_button_action_type) {
                return true;
              }
              if (action.action_button_action_type == "NAVIGATION") {
                if (
                  !action.action_button_action_navigation_type ||
                  !action.action_button_target_location
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type == "OPEN_URL" &&
                  (!action.action_button_target_link ||
                    !this.isValidURL(action.action_button_target_link))
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_DOCUMENT_TEMPLATE_BUILDER" &&
                  !action.action_button_document_template_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_FORM_BUILDER" &&
                  !action.action_button_formbuilder_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_ENTITY" &&
                  !action.action_button_entity_id
                ) {
                  return true;
                } else if (
                  action.action_button_action_navigation_type ==
                    "OPEN_ENTITY_VIEW" &&
                  !action.action_button_entity_view_id
                ) {
                  return true;
                }
                return false;
              } else if (
                action.action_button_action_type == "TEMPLATE_ACTION"
              ) {
                return false;
              } else if (
                action.action_button_action_type == "ESTABLISH_RELATIONSHIP"
              ) {
                if (!action.relational_entity_one) {
                  return true;
                }
                return false;
              } else if (
                action.action_button_action_type == "CREATE_DUPLICATE"
              ) {
                if (!action.selected_fields?.length) {
                  return true;
                }
              }
            });
            if (emptyField) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        }
        // if (
        //   field.label &&
        //   field.action_button_type &&
        //   field.action_button_action_type
        // ) {
        //   if (
        //     field.action_button_action_type == "OPEN_URL" &&
        //     field.action_button_target_location &&
        //     field.action_button_target_link &&
        //     this.isValidURL(field.action_button_target_link)
        //   ) {
        //     return false;
        //   } else if (
        //     field.action_button_action_type == "OPEN_FORM_BUILDER" &&
        //     field.action_button_formbuilder_id
        //   ) {
        //     return false;
        //   } else if (
        //     field.action_button_action_type ==
        //       "OPEN_DOCUMENT_TEMPLATE_BUILDER" &&
        //     field.action_button_document_template_id
        //   ) {
        //     return false;
        //   } else if (field.action_button_action_type == "TEMPLATE_ACTION") {
        //     return false;
        //   } else {
        //     return true;
        //   }
        // } else {
        //   return true;
        // }
      }
      if (field.input_type === "GLOBAL_VARIABLE") {
        if (field.label && field.global_variable_type) {
          if (
            field.global_variable_type == "ENTITY" &&
            field.global_variable_entity_type &&
            field.global_variable_entity_select_type &&
            (field.allow_new_entity_data || !field.allow_new_entity_data)
          ) {
            return false;
          } else if (
            field.global_variable_type == "USERS" &&
            field.global_variable_user_type
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (field.input_type === "ENTITY") {
        let allConfiguredFilter = true;
        if (field.filters && field.filters.length) {
          let nonConfiguredFilter = field.filters.find(
            (e) =>
              !e.field ||
              (e.field == "RESOURCE_MANAGEMENT" &&
                (!e.resource_count ||
                  !e.resource_source ||
                  !e.data_source ||
                  (!e.resource_value_field && !e.resource_value) ||
                  (!e.value && !e.value_field))) ||
              (e.field == "UNIQUE" &&
                (!e.data_source || (!e.value && !e.value_field))) ||
              (e.field != "UNIQUE" &&
                e.field != "RESOURCE_MANAGEMENT" &&
                (!e.operator ||
                  (!e.value &&
                    !e.value_field &&
                    typeof e.value !== "number" &&
                    e.data_source != "self_field" &&
                    e.data_source != "from_date" &&
                    e.operator != "exists" &&
                    e.operator != "notexists" &&
                    e.data_source != "GLOBAL_VARIABLE" &&
                    e.operator != "weekdays") ||
                  (!e.data_source &&
                    e.operator != "exists" &&
                    e.operator != "notexists" &&
                    e.operator != "between" &&
                    e.operator != "real_time" &&
                    e.operator != "weekdays")))
          );
          if (
            nonConfiguredFilter &&
            (nonConfiguredFilter.field == "self#related_to/parent" ||
              nonConfiguredFilter.field == "self#related_to/self") &&
            nonConfiguredFilter.operator !== ""
          ) {
            nonConfiguredFilter = undefined;
          }
          allConfiguredFilter = nonConfiguredFilter ? false : true;
        }
        return (
          !field.label ||
          !field.entity_id ||
          !allConfiguredFilter ||
          !field.primary_fields ||
          !field.primary_fields.length
        );
      }
      if (field.input_type === "ENTITY_VARIABLE") {
        let allowedTypes = ["NUMBER", "CURRENCY", "AGGREGATE_FUNCTION"];
        let parent = (this.fieldsData || []).find(
          (e) => e.key == field.relationship_key
        );
        if (
          parent?.allow_multiple &&
          (allowedTypes.indexOf(field.inputType) != -1 ||
            (field.inputType == "FORMULA" &&
              allowedTypes.indexOf(field.result_type) != -1))
        ) {
          return !field.variable_action;
        }
        return (
          !field.label ||
          !field.relationship_key ||
          !field.global_variable_entity_field
        );
      }
      if (field.input_type === "QUESTION") {
        return !field.question_text || !field.question_type;
      }
      return !field.label;
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    applyCustomizationOnColumns(fields, customization, templateData = null) {
      let allFields = this.applyCustomizationOnFields(
        fields,
        customization,
        templateData
      );
      return allFields.map((field) => {
        if (field?.newlyAddedField) {
          return {
            label: field.label,
            id: field.key,
            template_id: field?.template_id || templateData?.templateInfo?._id,
            type: field.inputType,
            currency_code: field.validations,
            entity_id: field.entity_id,
            key: field.key,
          };
        } else {
          return field;
        }
      });
    },
    applyCustomizationOnColumnSettings(currentEntity, columns) {
      let customizationObject = {};
      currentEntity.templates.forEach((template) => {
        customizationObject[template.template_id] = {
          ...template.customization,
        };
      });
      let mappedFields = columns.map((col) => {
        if (
          customizationObject?.[col.template_id]?.[col.id]?.["LABEL"] &&
          customizationObject?.[col.template_id]?.[col.id]?.["LABEL"] !==
            col.label
        ) {
          col.label = customizationObject[col.template_id][col.id]["LABEL"];
        }
        return col;
      });
      // Object.keys(customizationObject || {}).forEach((template_id) => {
      //   Object.keys(customizationObject[template_id] || {}).forEach((key) => {
      //     console.log(
      //       "template_idtemplate_id",
      //       template_id,
      //       columns,
      //       customizationObject[template_id][key]
      //     );
      //   });
      // });
      // console.log("mappedFieldsmappedFields", mappedFields);
      return mappedFields;
    },
    applyCustomizationOnFields(
      fields,
      customization,
      templateData = null,
      include_label = false
    ) {
      let mappedFields = fields.map((field) => {
        let key = field.key;
        if (key && key.includes("#")) {
          key = key.split("#")[1];
        }
        if (customization?.[key]) {
          if (customization[key]["LABEL"] !== field.label) {
            if (include_label) {
              field.label =
                field.template_name + " - " + customization[key]["LABEL"];
            } else {
              field.label = customization[key]["LABEL"];
            }
          }

          if (customization[key]["INPUT_TYPE"] !== field.input_type) {
            if (field.input_type !== "ENTITY_VARIABLE") {
              field.input_type = customization[key]["INPUT_TYPE"];
            }
            field.inputType = customization[key]["INPUT_TYPE"];
            if (customization[key]["INPUT_TYPE"] == "SELECT") {
              field.options = customization[key]["OPTIONS"];
            }
          }

          if (field?.properties) {
            field.properties["filed_content"] = customization[key]["HIDE"]
              ? "Hide"
              : customization[key]["READ_ONLY"]
              ? "READ ONLY"
              : "";
          } else {
            field["properties"] = {
              filed_content: customization[key]["HIDE"]
                ? "Hide"
                : customization[key]["READ_ONLY"]
                ? "READ ONLY"
                : "",
            };
          }

          if (field?.validations) {
            field.validations["required"] = customization[key]["REQUIRED"];
          } else {
            field["validations"] = {
              required: customization[key]["REQUIRED"],
            };
          }
          field.do_not_apply = true;
        }
        return field;
      });
      mappedFields = [
        ...mappedFields,
        ...this.addNewlyAddedFields(fields, customization, templateData),
      ];
      return mappedFields;
    },
    addNewlyAddedFields(fields, customization, templateData = null) {
      let newFields = [];
      let fieldActions = [
        ...fieldsList.allFormFields,
        ...fieldsList.allContentFields,
        ...fieldsList.allAdvancedFields,
      ];
      Object.keys(customization || {}).forEach((key) => {
        let found = fields.find(
          (e) => e.key == key || (e.key && e.key.split("#")[1] == key)
        );
        if (!found) {
          let field = this.getFieldFromCustomization(customization[key], key);
          newFields.push(field);
        }
      });
      let lastFieldByY = fields.reduce(
        (prev, curr) => {
          const value = Number(curr.y);
          if (prev.y < value && curr?.properties?.filed_content !== "Hide") {
            return {
              y: value,
              key: curr.key,
              height: curr.height,
              width: curr.width,
            };
          }
          return prev;
        },
        { y: 0, key: "", height: 0, width: 0 }
      );
      let lastFieldByX = fields.reduce(
        (prev, curr) => {
          const value = Number(curr.x);
          if (prev.x > value && curr?.properties?.filed_content !== "Hide") {
            return {
              x: value,
              key: curr.key,
              height: curr.height,
              width: curr.width,
            };
          }
          return prev;
        },
        { x: 900, y: 0, key: "", height: 0, width: 0 }
      );
      let offsetY = 50,
        offsetX = 50,
        y = lastFieldByY.y + lastFieldByY.height + offsetY,
        x = lastFieldByX.x;
      const templateWidth = 750;
      return newFields.map((field, i) => {
        let baseField = fieldActions.find((e) =>
          e.inputType ==
          ["MASKED", "HYPER_LINK", "PASSWORD", "EMAIL", "RICH_TEXT"].includes(
            field.input_type
          )
            ? field.inputType
            : field.input_type
        );
        let nextField = fieldActions.find(
          (e) => newFields[i + 1] && e.inputType == newFields[i + 1].input_type
        );
        field.y = y;
        field.x = x;
        if (templateData?.templateInfo) {
          field.template_name = templateData.templateInfo.name;
          field.template_id = templateData.templateInfo._id;
        }
        let tempX = field?.x + baseField?.width + offsetX;
        if (nextField && tempX + nextField?.width >= templateWidth) {
          y = field?.y + baseField?.height + offsetY;
          x = lastFieldByX?.x;
        } else {
          x = tempX;
        }
        field.newlyAddedField = true;
        return { ...baseField, ...field };
      });
    },
    getFieldFromCustomization(customObj, key) {
      return {
        ...(customObj.FIELD_DATA || {}),
        ...{
          label: customObj.LABEL,
          input_type: customObj.INPUT_TYPE,
          //inputType: customObj.INPUT_TYPE,
          key: key,
          validations: {
            ...(customObj &&
            customObj.FIELD_DATA &&
            customObj.FIELD_DATA.validations
              ? customObj.FIELD_DATA.validations
              : {}),
            ...{
              required: customObj.REQUIRED,
            },
          },
          properties: {
            filed_content: customObj.READ_ONLY
              ? "READ ONLY"
              : customObj.HIDE
              ? "Hide"
              : "",
          },
          options: customObj.OPTIONS,
        },
      };
    },
    getEntityTableDetails(field, form) {
      if (
        field?.key &&
        this.currentStep?.settings?.entity_table_configuration
      ) {
        let settings =
          this.currentStep.settings.entity_table_configuration.find(
            (e) => e.selectField == field.key
          );
        if (settings?.linked_min_field || settings?.linked_field) {
          let min = this.calculateMinNoOfRecords(
            settings?.linked_min_field,
            settings?.min_records
          );
          let max = this.calculateMaxNoOfRecords(
            settings?.linked_field,
            settings?.max_records
          );
          return {
            min,
            max,
          };
        }
      } else if (
        (field?.max_value || field?.max_value_map) &&
        (field?.min_value || field?.min_value_map)
      ) {
        return {
          min: field.min_value || form?.[field.min_value_map],
          max: field.max_value || form?.[field.max_value_map],
        };
      }
      return {
        min: 0,
        max: 1000,
      };
    },
    applyFormRules(form, fields, rules) {
      if (rules && rules.length) {
        rules.forEach((rule) => {
          let results = [];
          rule.conditions.forEach((condition) => {
            results.push(this.checkCondition(condition, form, fields));
          });
          if (rule.query_type == "AND" && results.every((el) => el)) {
            form = this.applyUpdateActions(
              rule.actions,
              form,
              fields,
              rule.conditions,
              rule
            );
          } else if (rule.query_type == "OR" && results.some((el) => el)) {
            form = this.applyUpdateActions(
              rule.actions,
              form,
              fields,
              rule.conditions,
              rule
            );
          }
        });
      }
      return form;
    },
    applyUpdateActions(actions, form, fields, conditions, rule) {
      let tempForm = JSON.parse(JSON.stringify(form));
      actions.forEach((action) => {
        tempForm = {
          ...tempForm,
          ...this.applyUpdateCondition(
            action,
            fields,
            tempForm,
            conditions,
            rule
          ),
        };
      });
      return tempForm;
    },
    applyUpdateCondition(action, fields, form, conditions, rule) {
      let value = this.fetchFieldValue(action, form, fields);
      let tempForm = JSON.parse(JSON.stringify(form));
      switch (action.operator_type) {
        case "UPDATE":
          // if (action?.data_source == "TEMPLATE") {
          //   this.$set(tempForm, action.selectField, form[action.value]);
          // } else {
          //   this.$set(tempForm, action.selectField, value);
          // }
          if (action.selectField && action.selectField.includes("#")) {
            let [tableKey, key] = action.selectField.split("#");
            let dataTableData = form[tableKey] || [];
            dataTableData = dataTableData.map((fl) => {
              let results = [];
              conditions.forEach((con) => {
                if (con.selectField && con.selectField.includes("#")) {
                  let [t, k] = con.selectField.split("#");
                  let newCon = { ...con, ...{ ["selectField"]: k }, t: t };
                  results.push(
                    this.checkCondition(newCon, { ...fl, ...form }, fields)
                  );
                }
              });
              if (
                conditions &&
                conditions.length &&
                ((rule?.query_type == "AND" && results.every((el) => el)) ||
                  (rule?.query_type == "OR" && results.some((el) => el)))
              ) {
                if (
                  action.input_type == "SELECT" &&
                  typeof value == "object" &&
                  value.length
                ) {
                  fl[key] = value[0];
                } else {
                  fl[key] = value;
                }
                // changed = true;
              }
              return fl;
            });
            this.$set(tempForm, tableKey, dataTableData);
          } else if (
            action.selectField &&
            action.operator_type === "UPDATE" &&
            action.data_source === "TEMPLATE" &&
            action.value.includes("#")
          ) {
            let [t, k] = action.value.split("#");
            let arr = form[t] || [];
            let p = arr
              .map((item) => item[k])
              .filter((value) => value !== undefined);
            if (p.length > 0) {
              if (action.selectedRowIndex === "first_row") {
                this.$set(tempForm, action.selectField, p[0]);
              } else if (action.selectedRowIndex === "last_row") {
                this.$set(tempForm, action.selectField, p[p.length - 1]);
              }
            }
          } else if (action.is_fixed == true && action.input_type == "TIME") {
            this.$set(tempForm, action.selectField, action.value);
          } else if (
            action.input_type == "TIME" &&
            action.data_source == "CURRENTIME"
          ) {
            const formattedValue = moment().format("HH:mm:ss");
            this.$set(tempForm, action.selectField, formattedValue);
          } else if (
            action.input_type == "DATE_TIME" &&
            action.data_source == "TODAY" &&
            action.data_type == "DATE_TIME"
          ) {
            const formattedValue = moment().toISOString();
            this.$set(tempForm, action.selectField, formattedValue);
          } else if (
            action.data_type == "DATE" &&
            action.data_source == "TODAY"
          ) {
            const formatedValue = moment().startOf("day").toISOString();
            this.$set(tempForm, action.selectField, formatedValue);
          } else if (action.input_type === "CHECKBOX_GROUP") {
            const filteredFields = fields.filter(
              (field) =>
                field.inputType === "CHECKBOX_GROUP" &&
                field.input_type === "CHECKBOX_GROUP"
            );

            (filteredFields || []).forEach((checkBox) => {
              const optionsArray = checkBox.options || [];
              const selectedOptions = action.value || [];
              const selectedCount = selectedOptions.length;
              if (
                selectedCount >= checkBox.min_selection &&
                selectedCount <= checkBox.max_selection
              ) {
                const isMatchFound = optionsArray.some((option) => {
                  const isMatch = selectedOptions.includes(option);
                  if (isMatch) {
                    const targetForm = this.form.require
                      ? this.form.require[0]
                      : this.form;
                    if (targetForm) {
                      this.$set(
                        targetForm,
                        action.selectField,
                        selectedOptions
                      );
                    }
                  }
                });
                console.log("ISMATCH", isMatchFound);
              } else {
                this.$message.error(
                  "Updated options count not within allowed range"
                );
              }
            });
          } else if (action.selectField === "entity_variable") {
            const filterField = fields.filter(
              (field) =>
                field.input_type === "ENTITY_VARIABLE" &&
                field.field_assignable === "read_only"
            );

            if (filterField.length > 0) {
              this.$notify({
                title: "Error",
                message:
                  "The selected field is in read-only state. Update not allowed.",
                type: "error",
              });
            } else {
              const targetForm = this.form.require
                ? this.form.require[0]
                : this.form;
              if (targetForm) {
                this.$set(targetForm, action.selectField, action.value);
              }
            }
          } else if (
            (action?.input_type == "DATE" ||
              action?.data_type == "NUMBER" ||
              action?.data_type == "STRING" ||
              action?.data_type == "OBJECT_ID") &&
            action.value &&
            action.data_source == "TEMPLATE"
          ) {
            this.$set(tempForm, action.selectField, this.form[action.value]);
          } else if (
            action.input_type === "MULTI_SELECT" ||
            action.input_type == "WEEKDAYS" ||
            action.input_type == "DATE_TIME_RANGE" ||
            action.input_type == "DATE_RANGE" ||
            action.input_type == "DATE" ||
            (action.input_type == "TIME_RANGE" &&
              action.value &&
              action.value.length)
          ) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              this.$set(targetForm, action.selectField, action.value);
            }
          } else if (
            action.data_type === "NUMBER" &&
            action.value &&
            action.operator !== "between" &&
            !action.selectField.includes("#")
          ) {
            let validationFailed = false;

            for (const field of fields) {
              if (
                (field.input_type === "NUMBER" ||
                  field.input_type === "CURRENCY") &&
                ((field.min_value !== null && field.min_value !== undefined) ||
                  (field.max_value !== null &&
                    field.max_value !== undefined)) && // Check if min_value or max_value is defined
                ((field.min_value !== null &&
                  field.min_value !== undefined &&
                  action.value < field.min_value) ||
                  (field.max_value !== null &&
                    field.max_value !== undefined &&
                    action.value > field.max_value))
              ) {
                const minValue = field.min_value;
                const maxValue = field.max_value;

                let errorMessage = `Error for ${action.selectField}: The updated value should`;
                if (minValue !== undefined && minValue !== null) {
                  errorMessage += ` be greater than or equal to ${minValue}`;
                }
                if (
                  (minValue !== undefined &&
                    maxValue !== undefined &&
                    maxValue !== null) ||
                  (minValue !== null && maxValue !== null)
                ) {
                  errorMessage += " and";
                }
                if (maxValue !== undefined && maxValue !== null) {
                  errorMessage += ` be less than or equal to ${maxValue}`;
                }

                Notification.error({
                  title: "Validation Error",
                  message: errorMessage,
                });

                validationFailed = true;
                break;
              }
            }

            if (!validationFailed) {
              const targetForm = this.form.require
                ? this.form.require[0]
                : this.form;
              if (targetForm) {
                this.$set(targetForm, action.selectField, action.value);
              }
            }
          } else if (action.input_type == "CHECKBOX" && action.value) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              this.$set(targetForm, action.selectField, action.value);
            }
          } else if (
            action.selectField == "time" &&
            action.input_type == "TIME"
          ) {
            const dateObject = new Date(action.value);
            const timeString = dateObject.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            });
            this.$set(tempForm, action.selectField, timeString);
          } else if (
            action.selectField == "fixed_time" &&
            action.input_type == "TIME"
          ) {
            const dateObject = new Date(action.value);
            const timeString = dateObject.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            this.$set(tempForm, action.selectField, timeString);
          } else if (action.data_source == "PAYMENT") {
            let keys = action.value.split("#");
            if (
              this.form &&
              keys[0] &&
              keys[1] &&
              this.form[keys[0] + "_info"] &&
              this.form[keys[0] + "_info"][keys[1]]
            ) {
              this.$set(
                this.form,
                action.selectField,
                this.form[keys[0] + "_info"][keys[1]]
              );
            }
          } else if (
            action.data_source == "login_user" &&
            action.value &&
            this.getAuthenticatedUser[action.value]
          ) {
            this.$set(
              this.form,
              action.selectField,
              this.getAuthenticatedUser[action.value]
            );
          } else if (action.input_type === "LIST") {
            action.value.forEach((item) => {
              let fieldName = action.selectField;
              let infoFieldName = action.selectField + "_info";
              this.$set(tempForm, fieldName, item.split("_")[0]);
              this.$set(tempForm, infoFieldName, item.split("_")[1]);
            });
          } else {
            if (action.selectField && action.selectField.includes("#")) {
              let [tableKey, key] = action.selectField.split("#");
              let dataTableData = form[tableKey] || [],
                changed = false;
              dataTableData = dataTableData.map((fl) => {
                let results = [];
                conditions.forEach((con) => {
                  if (con.selectField && con.selectField.includes("#")) {
                    let [t, k] = con.selectField.split("#");
                    // console.log("t", t, k);
                    let newCon = { ...con, ...{ ["selectField"]: k }, t: t };
                    results.push(this.checkCondition(newCon, fl, fields));
                  }
                });
                if (
                  conditions &&
                  conditions.length &&
                  results.every((e) => e)
                ) {
                  changed = true;
                  if (
                    action.input_type == "SELECT" &&
                    typeof value == "object" &&
                    value.length
                  ) {
                    fl[key] = value[0];
                  } else {
                    fl[key] = value;
                  }
                }
                return fl;
              });
              if (changed) {
                this.$set(tempForm, tableKey, dataTableData);
              }
            } else {
              this.$set(tempForm, action.selectField, String(value));
            }
          }
          break;
      }
      return tempForm;
    },
    isDate(string) {
      var dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      var dateRegex1 = /^\d{2}-\d{2}-\d{4}$/;
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (
        dateRegex.test(string) ||
        dateRegex1.test(string) ||
        dateStamp.test(string) ||
        timeRegex.test(string)
      ) {
        return true;
      }
      return false;
    },
    includeTemplates(getAllCompanyTemplatesData, templateData) {
      if (getAllCompanyTemplatesData?.data) {
        if (
          getAllCompanyTemplatesData.data.find((e) => e.type === "STANDARD") &&
          !templateData.find((e) => e.type === "STANDARD")
        ) {
          templateData = [
            ...templateData,
            ...[
              getAllCompanyTemplatesData.data.find(
                (e) => e.type === "STANDARD"
              ),
            ].concat(
              getAllCompanyTemplatesData.data.filter(
                (e) => e.type !== "STANDARD"
              )
            ),
          ];
        } else {
          templateData = [
            ...templateData,
            ...getAllCompanyTemplatesData.data.filter(
              (e) => e.type !== "STANDARD"
            ),
          ];
        }
      }
      return templateData;
    },
    includeEntities(getAllEntities, entitiesData) {
      if (getAllEntities?.data) {
        entitiesData = [
          ...entitiesData,
          ...getAllEntities.data.filter((e) => e.type !== "STANDARD"),
        ];
      }
      return entitiesData;
    },
    async fetchAllTemplates(include_standard = true) {
      try {
        let limit = 50,
          page = 1,
          templateData = [];
        // await this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        //   include_standard: true,
        //   limit: limit,
        //   page: page,
        // });
        const getAllCompanyTemplatesData =
          await getAllCompanyTemplatesByPagination({
            limit: limit,
            page: page,
            ...(include_standard && {
              include_standard: true,
            }),
          });
        templateData = this.includeTemplates(
          getAllCompanyTemplatesData,
          templateData
        );
        if (getAllCompanyTemplatesData?.data) {
          let totalPages = getAllCompanyTemplatesData.total_pages;
          let pageArray = [];
          for (let i = 2; i <= totalPages; i++) {
            pageArray.push(i);
          }
          await Promise.all(
            pageArray.map(async (p) => {
              const getAllCompanyTemplatesData =
                await getAllCompanyTemplatesByPagination({
                  limit: limit,
                  page: p,
                  ...(include_standard && {
                    include_standard: true,
                  }),
                });
              // await this.$store.dispatch(
              //   "companyTemplates/getAllCompanyTemplates",
              //   {
              //     include_standard: true,
              //     limit: limit,
              //     page: p,
              //   }
              // );
              if (getAllCompanyTemplatesData?.data) {
                templateData = this.includeTemplates(
                  getAllCompanyTemplatesData,
                  templateData
                );
              }
            })
          );
        }
        return templateData;
      } catch (e) {
        console.log("Template errors:", e);
        return [];
      }
    },
    async fetchAllEntities(include_standard = true) {
      try {
        let limit = 50,
          page = 1,
          entitiesData = [];
        const getAllEntities = await fetchEntitiesByPagination({
          populateData: true,
          limit: limit,
          page: page,
          ...(include_standard && {
            include_standard: true,
          }),
        });
        if (getAllEntities?.data) {
          let totalPages = getAllEntities.total_pages;
          entitiesData = this.includeEntities(getAllEntities, entitiesData);
          if (getAllEntities.has_more) {
            let pageArray = [];
            for (let i = 2; i <= totalPages; i++) {
              pageArray.push(i);
            }
            await Promise.all(
              pageArray.map(async (p) => {
                const getAllEntities = await fetchEntitiesByPagination({
                  populateData: true,
                  limit: limit,
                  page: p,
                  ...(include_standard && {
                    include_standard: true,
                  }),
                });
                if (getAllEntities?.data) {
                  entitiesData = this.includeEntities(
                    getAllEntities,
                    entitiesData
                  );
                }
              })
            );
          }
        }
        return entitiesData;
      } catch (e) {
        console.log("Entity errors:", e);
        return [];
      }
    },
    async fetchAllEntityViews() {
      try {
        let limit = 50,
          page = 1,
          entityViews = [];
        let getAllEntityViews = await fetchEntityViews({ limit, page });
        if (getAllEntityViews?.data) {
          let totalPages = getAllEntityViews.total_pages;
          entityViews = [...entityViews, ...getAllEntityViews.data];
          while (page < totalPages) {
            page++;
            getAllEntityViews = await fetchEntityViews({ limit, page });
            if (getAllEntityViews?.data) {
              entityViews.push(...getAllEntityViews.data);
            }
          }
        }
        return entityViews;
      } catch (err) {
        console.log("Entity views errors: ", err);
        return [];
      }
    },

    checkFilterConditions(data, filters, query_type = "AND") {
      if (filters) {
        return data.filter((dt) => {
          let form = JSON.parse(JSON.stringify(dt));
          let res = this.checkSingleData(form, filters, query_type);
          return res;
        });
      }
      return data;
    },
    checkSingleData(form, filters, query_type) {
      let results = [];
      filters.forEach((filter) => {
        results.push(this.checkSingleCondition(form, filter));
      });
      if (query_type == "AND" && results.every((el) => el)) {
        return true;
      } else if (query_type == "OR" && results.some((el) => el)) {
        return true;
      }
      return false;
    },
    checkSingleCondition(form, filter) {
      let result = false;
      let value1 = this.getFilterValue1(filter, form),
        value2 = this.getFilterValue2(filter, form);
      switch (filter.operator) {
        case ">":
          result = value1 > value2 ? true : false;
          break;
        case "<":
          result = value1 < value2 ? true : false;
          break;
        case ">=":
          result = value1 >= value2 ? true : false;
          break;
        case "<=":
          result = value1 <= value2 ? true : false;
          break;
        case "=":
          result =
            (value1 || value2 == false) && value1 == value2 ? true : false;
          break;
        case "!=":
          result = !(value1 || value2 == false) || value1 != value2;
          break;
        case "=text-includes":
          result = value1 && value1 && value1.includes(value2) ? true : false;
          break;
        case "=case-insensitive":
          result =
            value1 &&
            typeof value1 == "string" &&
            value2 &&
            value1.toLowerCase() == value2.toLowerCase()
              ? true
              : false;
          break;
        case "=case-sensitive":
          result = value1 && value1 == value2 ? true : false;
          break;
        case "notexists":
          result =
            (value1 == undefined || value1 == "") && typeof value1 != "number"
              ? true
              : false;
          break;
        case "exists":
          result = value1 && value1 != undefined && value1 != "" ? true : false;
          break;
        case "in":
          result = value2 && value1 && value2.some((v) => value1.includes(v));
          break;

        case "nin":
          result =
            value2 &&
            (!value1 ||
              !value1.length ||
              !value2.some((v) => value1.includes(v)));
          break;

        case "click":
          result = value1;
          break;
        case "!=case-insensitive":
          result =
            value1 &&
            typeof value1 == "string" &&
            value2 &&
            value1.toLowerCase() == value2.toLowerCase()
              ? false
              : true;
          break;
        case "=starts-with":
          result = value1 && value2 && value1.charAt(0) === value2.charAt(0);
          break;

        case "=not-starts-with":
          result = !value1 || !value2 || value1.charAt(0) !== value2.charAt(0);
          break;
        case "=ends-with":
          result = value1 && value2 && value1.slice(-value2.length) === value2;
          break;
        case "!ends-with":
          result = !(value1 && value1.slice(-value2.length) === value2);
          break;

        case "!=case-sensitive":
          result = value1 && value1 == value2 ? false : true;
          break;

        // case "range":
        //   result = value >= value1 && value <= value2;
        //   break;
      }
      return result;
    },
    getFilterValue1(filter, form) {
      if (filter?.selectField && form[filter.selectField]) {
        if (
          filter?.operator == "in" &&
          typeof form[filter.selectField] == "object"
        ) {
          return [form[filter.selectField]];
        }
        return form[filter.selectField];
      }
      return this.getDefaultValue(filter);
    },
    getFilterValue2(filter, form) {
      if (filter?.data_source == "VALUE" || filter?.data_source == "OPTIONS") {
        return filter.value;
      } else if (
        filter?.data_source == "TEMPLATE" &&
        filter?.value_field &&
        form[filter.value_field]
      ) {
        return form[filter.value_field];
      }
      return this.getDefaultValue(filter);
    },
    getDefaultValue(filter) {
      if (filter?.data_type == "NUMBER") {
        return 0;
      }
      return "";
    },
    async fetchOtherTemplates(fields, includeAllFields = false) {
      let tempIds = [],
        entityIds = [],
        fieldKeys = [];
      this.$store.commit(
        "entities/setAllEntitiesInTemplate",
        {},
        {
          root: true,
        }
      );
      this.$store.commit(
        "companyTemplates/setTemplateDataTempVariable",
        {},
        { root: true }
      );
      fields.forEach((field) => {
        if (field?.inputType == "ENTITY" && field.primary_fields) {
          if (!includeAllFields) {
            fieldKeys = [
              ...fieldKeys,
              ...field.primary_fields.map((e) => e?.split("#")[1]),
            ];
          }
        }
        if (
          field?.input_type == "ENTITY_VARIABLE" &&
          field?.global_variable_entity_field_template_id
        ) {
          tempIds.push(field.global_variable_entity_field_template_id);
          if (!includeAllFields) {
            fieldKeys.push(field.global_variable_entity_select_type_field_key);
          }
        }
        if (field?.inputType == "ENTITY") {
          entityIds.push(field.entity_id);
          if (field.primary_fields) {
            if (!includeAllFields) {
              fieldKeys = [
                ...fieldKeys,
                ...field.primary_fields.map((e) => e?.split("#")[1]),
              ];
            }
          }
        }
        if (field.inputType == "DATA_TABLE" && field.data_table_columns) {
          field.data_table_columns.forEach((e) => {
            if (
              e?.input_type == "ENTITY_VARIABLE" &&
              e?.global_variable_entity_field_template_id
            ) {
              tempIds.push(e.global_variable_entity_field_template_id);
              if (!includeAllFields) {
                fieldKeys.push(e.global_variable_entity_select_type_field_key);
              }
            }
            if (e?.inputType == "ENTITY") {
              entityIds.push(e.entity_id);
            }
          });
        }
      });
      let promises = [];
      promises.push(
        // this.$store.dispatch("globalVariables/fetchGlobalVariables", {
        //   get_all: true,
        //   company_id: this.company_id,
        // })
        fetchGlobalVariables({ get_all: true, company_id: this.company_id })
      );
      if (tempIds && tempIds.length) {
        promises.push(
          // this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          //   get_all: true,
          //   include_templates: Array.from(new Set(tempIds)),
          //   field_keys: fieldKeys,
          //   populate_data: true,
          // })
          getAllCompanyTemplatesByPagination({
            get_all: true,
            include_templates: Array.from(new Set(tempIds)),
            field_keys: Array.from(new Set(fieldKeys)),
            populate_data: true,
          })
        );
      } else {
        promises.push(null);
      }
      if (entityIds && entityIds.length) {
        promises.push(
          // this.$store.dispatch("entities/fetchEntities", {
          //   get_all: true,
          //   existed_entity_ids: Array.from(new Set(entityIds)),
          //   include_template_data: true,
          //   populateData: true,
          fetchEntitiesByPagination({
            get_all: true,
            existed_entity_ids: Array.from(new Set(entityIds)).filter(
              (e) => !["", null, undefined].includes(e)
            ),
            include_template_data: true,
            populateData: true,
          })
        );
      } else {
        promises.push(null);
      }
      const [globalvariable, getAllCompanyTemplatesData, getAllEntities] =
        await Promise.all(promises);
      this.$store.commit(
        "globalVariables/setAllGlobalVariables",
        globalvariable,
        {
          root: true,
        }
      );
      if (getAllEntities?.data && entityIds.length) {
        let obj = {};
        (getAllEntities.data || []).forEach((entity) => {
          if (
            entity?.templates &&
            !entity.templates.find((e) => !e?.template_id?._id)
          ) {
            entity.templates = entity.templates.map((temp) => {
              let entityPrimaryFields = entity.primaryFields.filter(
                (e) => e.template_id == temp.template_id?._id
              );
              let tempObj = temp.template_id;
              if (
                fieldKeys &&
                fieldKeys.length &&
                tempObj?.sections?.[0]?.fields &&
                tempObj?.sections?.[0]?.fields.length > 100
              ) {
                tempObj.sections[0].fields = tempObj.sections[0].fields.filter(
                  (e) =>
                    fieldKeys.includes(e.key) ||
                    entityPrimaryFields.includes(e.key)
                );
              }
              temp.templateInfo = tempObj;
              temp.template_id = tempObj._id;
              return temp;
            });
            obj[entity._id] = entity;
          }
        });
        this.$store.commit("entities/setAllEntitiesInTemplate", obj, {
          root: true,
        });
      }
      if (getAllCompanyTemplatesData?.data && tempIds.length) {
        let obj = {};
        (getAllCompanyTemplatesData.data || []).forEach((template) => {
          obj[template._id] = template;
        });
        this.$store.commit(
          "companyTemplates/setTemplateDataTempVariable",
          obj,
          { root: true }
        );
      }
    },
    mapDateWithTz(value, offset) {
      if (offset < 0) {
        value = moment(value)
          .subtract(offset * -1, "minutes")
          .toISOString();
      } else {
        value = moment(value).add(offset, "minutes").toISOString();
      }
      return value;
    },
    async tryUsingAISettingsResponse(
      fullPrompt,
      operation,
      isRegenerate = false
    ) {
      try {
        const session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
        if (isRegenerate) {
          fullPrompt += " Provide another response";
        }
        const payload = {
          Enter_your_prompt: fullPrompt,
          session_id: session_id,
          prompt_type: operation || "default",
          flag: true,
        };

        const response = await axios.post(
          "https://ai.esigns.io/ai_features/",
          payload
        );
        // const formattedResponse = this.formatResponseData(response.data.data, operation);
        // console.log("Response", formattedResponse);
        return response.data.data;
      } catch (error) {
        console.error("Error in tryUsingAISettingsResponse:", error);
        throw error;
      }
    },

    getUserPromptMessage(fieldsData, form, input_type = "RICH_TEXT") {
      let userPromptMessage = "";

      // if (!this.tryUsingAISettings.manualPrompt) {
      switch (this.tryUsingAISettings.userSelectedOperation) {
        case "content_generation":
          userPromptMessage = "Generate content for the following.";
          break;
        case "summary":
          userPromptMessage = "Summarize the content for the following.";
          break;
        case "keyword_extraction":
          userPromptMessage = "Extract keywords from the following.";
          break;
        case "sentiment_analysis":
          userPromptMessage = "Analyze the sentiment of the following.";
          break;
        default:
          console.error(
            `Unknown operation: ${this.tryUsingAISettings.userSelectedOperation}`
          );
          return "";
        // }
      }
      if (this.tryUsingAISettings.manualPrompt) {
        userPromptMessage += ` ${this.tryUsingAISettings.manualPrompt}.`;
      }

      if (this.selectedCharacteristics) {
        userPromptMessage += ` Incorporate the following characteristics: ${this.selectedCharacteristics}.`;
      }

      if (this.selectedTone) {
        userPromptMessage += ` in a ${this.selectedTone} way.`;
      }
      const selectedFields = fieldsData.filter((f) =>
        this.tryUsingAISettings.additonalFields?.includes(f.key)
      );
      let fieldDetails = (selectedFields || [])
        .map((f) => {
          let value = f.key?.includes("#")
            ? form[f.key.split("#")[1]]
            : form[f.key];
          return `${f.label}: ${value || ""}`;
        })
        .join("/n");
      let fullPrompt = `${userPromptMessage}\n data( ${fieldDetails}).`;
      if (input_type == "RICH_TEXT") {
        fullPrompt +=
          "Make sure the response is in HTML format with inline CSS.";
      }
      return fullPrompt;
    },
    formatResponseData(responseData, operation) {
      if (operation === "keyword_extraction") {
        if (Array.isArray(responseData)) {
          return responseData.join(", ");
        } else {
          return responseData;
        }
      }
      return responseData;
    },

    async getAIFieldsResponse(fieldsData, filteredFields = [], form, type) {
      let aiFields = [];
      if (filteredFields.length) {
        aiFields = filteredFields;
      } else {
        aiFields = fieldsData.filter(
          (fd) =>
            fd?.ai_settings?.isAIEnabled &&
            fd?.ai_settings?.runOnAction?.includes(type) &&
            form[fd.ai_settings?.selectedSourceField]
        );
      }
      await Promise.all(
        aiFields.map(async (field) => {
          try {
            const session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
            const userPromptMessage = this.generatePrompt(
              field,
              form,
              fieldsData
            );
            if (!userPromptMessage) {
              console.error(
                `userPrompt.message is undefined for field ${field.key}`
              );
              return;
            }
            let payload = {
              Enter_your_prompt: userPromptMessage,
              session_id: session_id,
              prompt_type: field.ai_settings.selectedOperation,
              flag: true,
            };
            let response = await axios.post(
              "https://ai.esigns.io/ai_features/",
              payload
            );
            let formattedValue = this.getFormattedValue(
              response.data.data,
              field.ai_settings.selectedOperation,
              field.inputType
            );
            this.$set(form, field.key, formattedValue);
          } catch (error) {
            console.error(`Error processing field ${field.key}:`, error);
          }
        })
      );
      return form;
    },

    generatePrompt(field, form, fieldsData, isFromTemplate = false) {
      let settings = field.ai_settings;
      let allFieldsObject = {};
      fieldsData.forEach((e) => {
        allFieldsObject[e.key] = e;
      });
      let prompt = "";
      switch (settings.selectedOperation) {
        case "content_generation":
          prompt = `Generate content for the following data `;
          break;
        case "keyword_extraction":
          prompt = `Extract keywords from the following data `;
          break;
        case "sentiment_analysis":
          prompt = `Do a sentimental analysis on the following data`;
          break;
        case "summary":
          prompt = `Summarize the content for the following`;
          break;
        default:
          prompt = "";
      }
      if (settings.selectedSourceField) {
        if (isFromTemplate) {
          prompt += ` {{${
            allFieldsObject[settings.selectedSourceField]?.label
          }}}`;
        } else {
          prompt += `(${
            allFieldsObject[settings.selectedSourceField]?.label
          } - ${form[settings.selectedSourceField]})`;
        }
      }
      if (settings.selectedAdditionalFields?.length) {
        let text = "";
        settings.selectedAdditionalFields.map((e) => {
          if (isFromTemplate) {
            text += `${allFieldsObject[e]?.label},`;
          } else {
            text += `${allFieldsObject[e]?.label} : ${form[e]} ,`;
          }
        });
        prompt += ` .Also use the following variables data (${text}).`;
      }
      if (settings.userPrompt && !isFromTemplate) {
        prompt += `Also consider the following inputs : ${settings.userPrompt}`;
      }
      if (
        (field.inputType == "RICH_TEXT" || field.input_type == "RICH_TEXT") &&
        !isFromTemplate
      ) {
        prompt += "Make sure the response is in html format with inline css";
      }
      return prompt;
    },
    updateUserPrompts(form, updatedPrompts) {
      Object.keys(updatedPrompts).forEach((key) => {
        if (form[key]) {
          form[key] = updatedPrompts[key];
        }
      });
    },

    mapDefaultValues(
      allFields,
      form,
      whileSaving = false,
      mainForm = {},
      isDataTable = false
    ) {
      if (
        typeof form == "string" ||
        typeof form == "number" ||
        typeof form == "undefined"
      ) {
        form = {};
      }
      form = JSON.parse(JSON.stringify(form));
      mainForm = JSON.parse(JSON.stringify(mainForm));
      let fields = allFields ? JSON.parse(JSON.stringify(allFields)) : [];
      if (!whileSaving) {
        fields = fields.filter((e) => !e?.apply_default_at_end);
      }
      fields.forEach((field) => {
        let offset = new Date().getTimezoneOffset();
        offset = Number(offset || 0) * -1;
        if (
          field?.input_type == "PAY_BUTTON" &&
          form[field.key] == "NO ACTION DONE"
        ) {
          form[field.key + "_status"] = "UNPAID";
        }
        //converting to number
        if (
          (field?.inputType == "NUMBER" ||
            field?.inputType == "FORMULA" ||
            field?.inputType == "CURRENCY" ||
            field?.inputType == "AGGREGATE_FUNCTION") &&
          form[field.key] &&
          this.isNumber(form[field.key]) &&
          !this.isDate(form[field.key]) &&
          !this.isTime(form[field.key])
        ) {
          form[field.key] = Number(form[field.key]);
        }
        if (
          field?.input_type == "MASKED" &&
          form[field.key + "realValue"] &&
          whileSaving
        ) {
          form[field.key] = form[field.key + "realValue"];
        }
        if (field?.inputType == "DATE" && form[field.key] && whileSaving) {
          form[field.key + "/dateformat"] = this.mapDateWithTz(
            this.mapDateWithTz(
              this.mapDateWithTz(form[field.key], offset),
              720
            ),
            new Date().getTimezoneOffset()
          );
        } else if (field?.inputType == "DATE_TIME" && form[field.key]) {
          if (
            field.properties.autofill_datetime_response == true &&
            !this.isView &&
            !form[field.key]
          ) {
            // let localTimezone = 'Pacific/Midway'
            let localTimezone =
              Intl.DateTimeFormat().resolvedOptions().timeZone;
            let currentTime = moment().tz(localTimezone);
            this.$set(form, field.key, currentTime);
          }
          form[field.key + "/dateformat"] = this.mapDateWithTz(
            form[field.key],
            offset
          );
          form[field.key + "/dateformat"] = moment
            .utc(form[field.key + "/dateformat"])
            .format("YYYY-MM-DD");
          form[field.key + "/dateformat"] = moment(
            form[field.key + "/dateformat"]
          )
            .add(12, "hours")
            .toISOString();
        }
        if (
          field.default_mapped_field &&
          field.table_first_row &&
          form[field.default_mapped_field_parent]?.length
        ) {
          let value =
            form[field.default_mapped_field_parent][0][
              field.default_mapped_field
            ];
          this.$set(form, field.key, value);
        }
        if (
          field.default_mapped_field &&
          field.table_last_row &&
          form[field.default_mapped_field_parent]?.length
        ) {
          let value =
            form[field.default_mapped_field_parent][
              form[field.default_mapped_field_parent].length - 1
            ][field.default_mapped_field];
          this.$set(form, field.key, value);
        }
        if (field?.inputType === "MULTI_SELECT") {
          if (!Array.isArray(form[field.key]) || !form[field.key]?.length) {
            form[field.key] =
              field.default_value && Array.isArray(field.default_value)
                ? field.default_value
                : [];
          }
        }
        if (field.input_type === "CHECKBOX_GROUP") {
          if (
            (!form[field.key] ||
              !Array.isArray(form[field.key]) ||
              !form[field.key]?.length) &&
            whileSaving &&
            field.apply_default_at_end
          ) {
            if (Array.isArray(field.default_value)) {
              form[field.key] = field.default_value;
            } else if (
              field.default_value &&
              !Array.isArray(field.default_value)
            ) {
              form[field.key] = [field.default_value];
            } else {
              form[field.key] = [];
            }
          }
        }

        let percentage =
          field?.inputType == "NUMBER" &&
          field?.properties?.number_type === "PERCENTAGE"
            ? true
            : false;
        if (
          ((!form[field.key] &&
            ((typeof form[field.key] !== "number" && !percentage) ||
              percentage)) ||
            (!isDataTable && field.replace_value) ||
            (isDataTable && !form["newlyAddedData"] && field.replace_value)) &&
          field.default_mapped_field &&
          (form[field.default_mapped_field] ||
            mainForm[field.default_mapped_field])
        ) {
          let value = form[field.default_mapped_field]
            ? form[field.default_mapped_field]
            : mainForm[field.default_mapped_field];
          let valueName = form[field.default_mapped_field + "/name"]
            ? form[field.default_mapped_field + "/name"]
            : mainForm[field.default_mapped_field + "/name"]
            ? mainForm[field.default_mapped_field + "/name"]
            : "";
          if (!field.apply_default_at_end) {
            this.$set(form, field.key, value);
            if (valueName) {
              this.$set(form, field.key + "/name", valueName);
            }
          } else if (whileSaving && field.apply_default_at_end) {
            this.$set(form, field.key, value);
            if (valueName) {
              this.$set(form, field.key + "/name", valueName);
            }
          }
        } else if (
          field.default_mapped_field &&
          form[field.default_mapped_field] &&
          field.add_default_value &&
          (form[field.key] || form[field.key] == 0)
        ) {
          if (!whileSaving && !field.apply_default_at_end) {
            this.$set(
              form,
              field.key,
              parseInt(form[field.key]) +
                parseInt(form[field.default_mapped_field])
            );
          } else if (whileSaving && field.apply_default_at_end) {
            this.$set(
              form,
              field.key,
              parseInt(form[field.key]) +
                parseInt(form[field.default_mapped_field])
            );
          }
          // form[field.key] =
          //   parseInt(form[field.key]) + parseInt(field.add_default_value);
        } else if (
          field.default_value &&
          !form[field.key] &&
          ((typeof form[field.key] !== "number" && !percentage) || percentage)
        ) {
          let parsedString;
          switch (field.input_type) {
            case "CHECKBOX_GROUP":
              if (
                typeof form[field.key] !== "object" ||
                !form[field.key]?.length
              ) {
                if (field.default_value && Array.isArray(field.default_value)) {
                  form[field.key] = field.default_value;
                } else if (
                  field.default_value &&
                  !Array.isArray(field.default_value)
                ) {
                  form[field.key] = [field.default_value];
                } else {
                  form[field.key] = [];
                }
              }
              break;
            case "RICH_TEXT":
              parsedString = this.getParsedHtmlString(
                form,
                field.default_value,
                fields
              );
              form[field.key] = parsedString;
              break;
            default:
              form[field.key] = field.default_value;
              break;
          }
        }
        //check table fields also
        if (field.inputType == "DATA_TABLE" && form[field.key] && Array.isArray(form[field.key])) {
          let dataTablevalue = (form[field.key] || []).map((tableData) => {
            let newTableData = this.mapDefaultValues(
              field.data_table_columns,
              tableData,
              whileSaving,
              form,
              true
            );
            return newTableData;
          });
          this.$set(form, field.key, dataTablevalue);
        }
      });
      return form;
    },
    parseEmailTemplate(
      regex = /\[\[(.*?)\]\]/g,
      body,
      allFields,
      revert = false
    ) {
      // const childVariables = [];
      // let match;
      // while ((match = regex.exec(body)) !== null) {
      //   childVariables.push(match[1]);
      // }
      let fieldsObject = {};
      allFields?.map((e) => {
        if (revert) {
          if (e.template_key && !e.data_table_key) {
            fieldsObject[e.template_key] = e;
          } else {
            fieldsObject[e.key] = e;
          }
        } else {
          fieldsObject[e.label] = e;
        }
      });
      body = body.replace(regex, (match, label) => {
        if (label.split("#").length == 3) {
          label = label.split("#")[0] + "#" + label.split("#")[1];
        }
        const template = fieldsObject[label];
        let value = revert
          ? template?.label
          : template?.template_key && !template.data_table_key
          ? template.template_key
          : template?.key;
        if (template?.input_type == "PAY_BUTTON" && !revert) {
          return template ? `[[${value}#pay]]` : match;
        }
        return template ? `[[${value}]]` : match;
      });
      return body;
    },
    isTime(string) {
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      if (timeRegex.test(string)) {
        return true;
      }
      return false;
    },
    isNumber(value) {
      if (
        (typeof value === "number" && !isNaN(value)) ||
        (typeof value === "string" && !isNaN(Number(value)))
      ) {
        return true;
      }
      if (typeof value === "string") {
        return /^[+-]?\d+(\.\d+)?$/.test(value) && !isNaN(parseFloat(value));
      }
      return false;
    },
    checkSingleRule(rule, form, fields) {
      let results = [];
      rule.conditions.forEach((condition) => {
        results.push(this.checkCondition(condition, form, fields));
      });
      if (rule.query_type == "AND" && results.every((el) => el)) {
        return true;
      } else if (rule.query_type == "OR" && results.some((el) => el)) {
        return true;
      }
      return false;
    },
    getFieldStyle(column, data) {
      if (data.style) {
        return "";
      }
      if (
        this.currentEntity?.templates &&
        data?.entityData &&
        data.entityData[column.template_id]
      ) {
        let temp = this.currentEntity.templates.find(
          (e) => e.template_id == column.template_id
        );
        if (
          temp?.templateInfo?.rules &&
          temp?.templateInfo?.sections[0]?.fields
        ) {
          let colorRules = temp.templateInfo.rules.filter((e) => {
            if (
              e.isRuleActive &&
              e?.actions &&
              e.actions.find(
                (el) =>
                  el.operator_type == "CHANGE_COLOR" &&
                  el.selectField == column.id
              )
            ) {
              return e;
            }
          });
          if (colorRules && colorRules.length) {
            let fields = temp.templateInfo.sections[0].fields.map((fl) => {
              if (fl.key && fl.key.includes("#")) {
                fl.key = fl.key.split("#")[1];
              }
              return fl;
            });
            return this.checkEntityDataConditions(
              column.id,
              colorRules,
              data.entityData[column.template_id],
              fields
            );
          }
          return "";
        }
        return "";
      }
      return "";
    },
    checkEntityDataConditions(key, rules, form, fields, returnResult = false) {
      let styles = "",
        rulesResults = {};
      rules.forEach((rule) => {
        if (!rule.isRuleActive) {
          return;
        }
        let results = [];
        rule.conditions.forEach((condition) => {
          results.push(
            this.checkCondition(condition, form, fields, rule.conditions)
          );
        });
        if (rule.query_type == "AND" && results.every((el) => el)) {
          styles =
            styles +
            this.applyEntityDataActions(key, rule.actions, form, fields);
          rulesResults[rule.name] = true;
        } else if (rule.query_type == "OR" && results.some((el) => el)) {
          styles =
            styles +
            this.applyEntityDataActions(key, rule.actions, form, fields);
          rulesResults[rule.name] = true;
        } else {
          rulesResults[rule.name] = false;
        }
      });
      if (returnResult) {
        return {
          rulesResults,
          style: styles,
        };
      }
      return styles;
    },
    applyEntityDataActions(key, actions, form, fields) {
      let styles = "";
      actions.forEach((action) => {
        if (key == action.selectField) {
          styles = styles + this.applyStyleCondition(action, form, fields);
        }
      });
      return styles;
    },
    applyStyleCondition(action) {
      let style = "";
      switch (action.operator_type) {
        case "CHANGE_COLOR":
          if (action.property_type == "BACKGROUND_COLOR" && action.color) {
            style =
              style +
              " background: " +
              action.color +
              " !important; padding: 5px;";
          }
          if (action.property_type == "FONT_COLOR" && action.color) {
            style =
              style +
              " color: " +
              action.color +
              " !important; font-weight: bold;";
          }
          break;
      }
      return style;
    },
    async applyRulesOnRepeatableData(
      fields,
      rules,
      rowIndex,
      indexKey,
      isIntial = false,
      addingData = false
    ) {
      if (rules.length) {
        if (this.hideFields && this.hideFields[indexKey]) {
          this.hideFields[indexKey] = [];
        }
        if (this.disabledField && this.disabledField[indexKey]) {
          this.disabledField[indexKey] = [];
        }
        let form;
        if (isIntial) {
          form = JSON.parse(
            JSON.stringify({
              ...this.entitiesData[rowIndex],
              ...{ indexKey: indexKey },
            })
          );
        } else {
          form = JSON.parse(
            JSON.stringify({ ...this.selectedRow, ...{ indexKey: indexKey } })
          );
        }
        await rules.forEach((rule) => {
          let results = [];
          rule.conditions.forEach((condition) => {
            results.push(this.checkCondition(condition, form, fields));
          });
          if (rule.query_type == "AND" && results.every((el) => el)) {
            form = this.applyActionsOnRepeatable(
              rule.actions,
              form,
              indexKey,
              fields
            );
          } else if (rule.query_type == "OR" && results.some((el) => el)) {
            form = this.applyActionsOnRepeatable(
              rule.actions,
              form,
              indexKey,
              fields
            );
          } else {
            form = this.removeActionsOnRepeatable(rule.actions, form, indexKey);
          }
        });
        this.selectedRow = { ...form };
        if (!addingData) {
          this.entitiesData[rowIndex] = { ...form };
          await (Object.keys(form) || []).forEach((key) => {
            if (this.entitiesData[rowIndex] && key != "indexKey") {
              this.entitiesData[rowIndex][key] = form[key];
            }
          });
        }
      }
    },
    removeActionsOnRepeatable(actions, form, rowIndex) {
      actions.forEach((action) => {
        let hideIndex = -1;
        if (
          this.templateRuleFields &&
          this.templateRuleFields.hideFields &&
          this.templateRuleFields.hideFields[rowIndex]
        ) {
          hideIndex = this.templateRuleFields.hideFields[rowIndex].findIndex(
            (e) => e == action.selectField
          );
        }
        //  let showIndex = this.hideFields.findIndex(e => e == action.selectField);
        let disabledIndex = -1;
        if (
          this.templateRuleFields &&
          this.templateRuleFields.disabledFields &&
          this.templateRuleFields.disabledFields[rowIndex]
        ) {
          disabledIndex = this.templateRuleFields.disabledFields[
            rowIndex
          ].findIndex((e) => e == action.selectField);
        }
        // let updateValue = "";
        // if (action.data_type == "NUMBER") {
        //   updateValue = 0;
        // }
        switch (action.operator_type) {
          // case "UPDATE":
          //   this.$set(form, action.selectField, updateValue);
          //   break;
          case "HIDE":
            if (hideIndex > -1) {
              this.templateRuleFields.hideFields[rowIndex].splice(hideIndex, 1);
            }
            break;
          case "SHOW":
            if (
              this.templateRuleFields &&
              this.templateRuleFields.hideFields &&
              this.templateRuleFields.hideFields[rowIndex] &&
              hideIndex == -1
            ) {
              this.templateRuleFields.hideFields[rowIndex].push(
                action.selectField
              );
            } else {
              if (
                this.templateRuleFields &&
                this.templateRuleFields.hideFields
              ) {
                this.$set(this.templateRuleFields.hideFields, rowIndex, [
                  action.selectField,
                ]);
              } else {
                this.$set(this.templateRuleFields, "hideFields", {
                  [rowIndex]: [action.selectField],
                });
              }
            }
            //  this.hideFields = this.hideFields.filter(e => e !== action.selectField);

            break;
          case "DISABLE":
            if (disabledIndex > -1) {
              this.templateRuleFields.disabledFields[rowIndex].splice(
                disabledIndex,
                1
              );
            }
            break;
        }
      });
      return form;
    },
    applyActionsOnRepeatable(actions, form, rowIndex, fields) {
      actions.forEach((action) => {
        form = {
          ...form,
          ...this.applySingleConditionOnRepeatable(
            action,
            form,
            rowIndex,
            fields
          ),
        };
      });
      return form;
    },
    applySingleConditionOnRepeatable(action, form, rowIndex, fields) {
      let value = this.fetchFieldValue(action, form, fields);

      switch (action.operator_type) {
        case "UPDATE":
          this.$set(form, action.selectField, value);
          break;
        case "SHOW":
          if (
            this.templateRuleFields &&
            this.templateRuleFields.hideFields &&
            this.templateRuleFields.hideFields[rowIndex]
          ) {
            let exIndex = this.templateRuleFields.hideFields[rowIndex].indexOf(
              action.selectField
            );
            if (exIndex != -1) {
              this.templateRuleFields.hideFields[rowIndex].splice(
                action.selectField,
                1
              );
            }
          }
          break;
        case "HIDE":
          if (this.templateRuleFields && this.templateRuleFields.hideFields) {
            if (this.templateRuleFields.hideFields[rowIndex]) {
              let exIndex = this.templateRuleFields.hideFields[
                rowIndex
              ].indexOf(action.selectField);
              if (exIndex == -1) {
                this.templateRuleFields.hideFields[rowIndex].push(
                  action.selectField
                );
              }
            } else {
              this.$set(this.templateRuleFields.hideFields, rowIndex, [
                action.selectField,
              ]);
            }
          } else {
            this.$set(this.templateRuleFields, "hideFields", {
              [rowIndex]: [action.selectField],
            });
          }
          break;
        case "DISABLE":
          if (
            this.templateRuleFields &&
            this.templateRuleFields.disabledFields
          ) {
            if (this.templateRuleFields.disabledFields[rowIndex]) {
              let exIndex = this.templateRuleFields.disabledFields[
                rowIndex
              ].indexOf(action.selectField);
              if (exIndex == -1) {
                this.templateRuleFields.disabledFields[rowIndex].push(
                  action.selectField
                );
              }
            } else {
              this.$set(this.templateRuleFields.disabledFields, rowIndex, [
                action.selectField,
              ]);
            }
          } else {
            this.$set(this.templateRuleFields, "disabledFields", {
              [rowIndex]: [action.selectField],
            });
          }
          break;
      }
      return form;
    },
    applyRulesOnDataTableFields(
      fields,
      rules,
      rowIndex,
      performSaveActions = false,
      applyOnPopup = false
    ) {
      this.showTemplateFields = [];
      this.hideFields = this.hideFields.filter(
        (fd) => fd.split("$")[1].toString() !== rowIndex.toString()
      );
      this.disabledFields = [];
      let form;
      if (applyOnPopup) {
        form =
          this.splitedDatatableRows[this.dataTableData?.[rowIndex]?.keyIndex];
      } else {
        form = this.rowsData[rowIndex].form;
      }
      rules.forEach((rule) => {
        if (!rule.isRuleActive) {
          return;
        }
        let results = [];
        if (rule?.show_alert) {
          if (performSaveActions) {
            rule.conditions.forEach((condition) => {
              results.push(this.checkCondition(condition, form, fields));
            });
            if (rule.query_type == "AND" && results.every((el) => el)) {
              this.applyActionsOnDataTable(rule.actions, rowIndex, fields);
            } else if (rule.query_type == "OR" && results.some((el) => el)) {
              this.applyActionsOnDataTable(rule.actions, rowIndex, fields);
            } else {
              this.removeActionsOnDataTable(rule.actions, rowIndex);
            }
          }
          return;
        }
        rule.conditions.forEach((condition) => {
          results.push(this.checkCondition(condition, form, fields));
        });
        if (rule.query_type == "AND" && results.every((el) => el)) {
          this.applyActionsOnDataTable(
            rule.actions,
            rowIndex,
            fields,
            applyOnPopup
          );
        } else if (rule.query_type == "OR" && results.some((el) => el)) {
          this.applyActionsOnDataTable(
            rule.actions,
            rowIndex,
            fields,
            applyOnPopup
          );
        } else {
          this.removeActionsOnDataTable(rule.actions, rowIndex);
        }
      });
    },

    applyActionsOnDataTable(actions, rowIndex, fields, applyOnPopup = false) {
      actions.forEach((action) => {
        this.applySingleActionOnDataTable(
          action,
          rowIndex,
          fields,
          applyOnPopup
        );
      });
    },
    applySingleActionOnDataTable(
      action,
      rowIndex,
      fields,
      applyOnPopup = false
    ) {
      let form;
      if (applyOnPopup) {
        form =
          this.splitedDatatableRows[this.dataTableData?.[rowIndex]?.keyIndex];
      } else {
        form = this.rowsData[rowIndex].form;
      }
      let k =
        this.rowsData[rowIndex].form.keyIndex ||
        this.dataTableData?.[rowIndex]?.keyIndex;
      if (action.selectField == "NOTIFICATION") {
        if (
          (action?.show_alert_once && this.executedNotifications[k]) ||
          this.isView
        ) {
          return;
        }
        if (action?.message) {
          if (action.notify_type == "message") {
            this.$message(action.message);
          } else {
            this.$message({
              message: action.message,
              type: action.notify_type,
            });
          }
          if (action?.show_alert_once) {
            this.executedNotifications[k] = true;
          }
        }
        return;
      }
      let hideIndex = this.hideFields.findIndex(
        (e) => e == action.selectField + "$" + rowIndex
      );
      let disabledIndex = this.disabledFields.findIndex(
        (e) => e == action.selectField + rowIndex
      );
      let value = this.fetchFieldValue(action, form, fields);
      switch (action.operator_type) {
        case "CHANGE_COLOR":
          if (this.colorFields) {
            this.$set(this.colorFields, action.selectField + "$" + rowIndex, {
              ...(this.colorFields?.[action.selectField + "$" + rowIndex] ||
                {}),

              ...{
                [action.property_type]: action.color,
              },
            });
          }
          break;
        case "UPDATE_PROPERTY":
          if (action.input_type == "CHECKBOX_GROUP") {
            if (action.property == "MAX") {
              this.setValuesToDataTable(
                applyOnPopup,
                rowIndex,
                value,
                action.selectField + "/max_value"
              );
            } else if (action.property == "MIN") {
              this.setValuesToDataTable(
                applyOnPopup,
                rowIndex,
                value,
                action.selectField + "/min_value"
              );
            }
          }
          break;
        case "empty_field":
          if (action.selectField) {
            for (const field of fields) {
              if (
                field.input_type == "ENTITY_VARIABLE" &&
                field.relationship_key &&
                field.relationship_key == action.selectField
              ) {
                this.$set(this.form, field.key, null);
                this.$set(this.form, action.selectField, null);
              }
              this.setValuesToDataTable(
                applyOnPopup,
                rowIndex,
                null,
                action.selectField
              );
            }
          }
          break;

        case "INCREMENT":
          if (action.selectField && action.data_source == "TEMPLATE") {
            let selectedFieldValue, templateFieldValue;
            if (applyOnPopup) {
              selectedFieldValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.selectField] || 0;
              templateFieldValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.value] || 0;
            } else {
              selectedFieldValue =
                this.rowsData[rowIndex].form[action.selectField] || 0;
              templateFieldValue =
                this.rowsData[rowIndex].form[action.value] || 0;
            }
            let sumValue =
              parseFloat(selectedFieldValue) + parseFloat(templateFieldValue);
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              sumValue,
              action.selectField
            );
          } else if (action.selectField && action.data_source === "VALUE") {
            let currentValue, valueToAdd;
            if (applyOnPopup) {
              currentValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.selectField] || 0;
              valueToAdd = value || 0;
            } else {
              currentValue =
                this.rowsData[rowIndex].form[action.selectField] || 0;
              valueToAdd = value || 0;
            }
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              parseFloat(currentValue) + parseFloat(valueToAdd),
              action.selectField
            );
          }
          break;
        case "DECREMENT":
          if (action.selectField && action.data_source == "TEMPLATE") {
            let selectedFieldValue, templateFieldValue;
            if (applyOnPopup) {
              selectedFieldValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.selectField] || 0;
              templateFieldValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.value] || 0;
            } else {
              selectedFieldValue =
                this.rowsData[rowIndex].form[action.selectField] || 0;
              templateFieldValue =
                this.rowsData[rowIndex].form[action.value] || 0;
            }
            let sumValue =
              parseFloat(selectedFieldValue) - parseFloat(templateFieldValue);
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              sumValue,
              action.selectField
            );
          } else if (action.selectField && action.data_source === "VALUE") {
            let currentValue,
              valueToAdd = value || 0;
            if (applyOnPopup) {
              currentValue =
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][action.selectField] || 0;
            } else {
              currentValue =
                this.rowsData[rowIndex].form[action.selectField] || 0;
            }
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              parseFloat(currentValue) - parseFloat(valueToAdd),
              action.selectField
            );
          }
          break;
        case "Make_this_field_required":
          fields = fields.map((item) => {
            if (item.key == action.selectField) {
              item.validations.required = true;
            }
          });
          break;
        case "Make_this_field_unrequired":
          fields = fields.map((item) => {
            if (item.key == action.selectField) {
              item.validations.required = false;
            }
          });
          break;

        case "UPDATE":
          if (action.input_type === "CHECKBOX_GROUP" && action.is_individual) {
            const selectedGroupKey = action.selectField; // Assuming action.group_key contains the desired group key value

            const filteredFields = fields.filter(
              (field) =>
                field.inputType === "CHECKBOX" &&
                field.is_check_box_group &&
                field.group_key === selectedGroupKey
            );
            (filteredFields || []).forEach((checkBox) => {
              if (checkBox?.key && value.indexOf(checkBox.key) != -1) {
                this.setValuesToDataTable(
                  applyOnPopup,
                  rowIndex,
                  true,
                  checkBox.key
                );
              } else {
                this.setValuesToDataTable(
                  applyOnPopup,
                  rowIndex,
                  false,
                  checkBox.key
                );
              }
            });
            // Perform further operations with the filteredFields array
          } else if (
            action.data_source === "VALUE" &&
            action.data_type === "TIME"
          ) {
            if (action.is_fixed) {
              const fixedTime = action.value;
              this.setValuesToDataTable(
                applyOnPopup,
                rowIndex,
                fixedTime,
                action.selectField
              );
            } else {
              const timeValue = new Date(action.value).toLocaleTimeString();
              this.setValuesToDataTable(
                applyOnPopup,
                rowIndex,
                timeValue,
                action.selectField
              );
            }
          } else if (action.data_source == "PAYMENT") {
            let keys = action.value.split("#");
            if (applyOnPopup) {
              if (
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ] &&
                keys[0] &&
                keys[1] &&
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][keys[0] + "_info"] &&
                this.splitedDatatableRows[
                  this.dataTableData?.[rowIndex]?.keyIndex
                ][keys[0] + "_info"][keys[1]]
              ) {
                this.$set(
                  this.splitedDatatableRows[
                    this.dataTableData?.[rowIndex]?.keyIndex
                  ],
                  action.selectField,
                  this.splitedDatatableRows[
                    this.dataTableData?.[rowIndex]?.keyIndex
                  ][keys[0] + "_info"][keys[1]]
                );
              }
            } else {
              if (
                this.rowsData[rowIndex].form &&
                keys[0] &&
                keys[1] &&
                this.rowsData[rowIndex].form[keys[0] + "_info"] &&
                this.rowsData[rowIndex].form[keys[0] + "_info"][keys[1]]
              ) {
                this.$set(
                  this.rowsData[rowIndex].form,
                  action.selectField,
                  this.rowsData[rowIndex].form[keys[0] + "_info"][keys[1]]
                );
              }
            }
          } else if (
            action.data_source == "login_user" &&
            action.value &&
            this.getAuthenticatedUser[action.value]
          ) {
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              this.getAuthenticatedUser[action.value],
              action.selectField
            );
          } else if (action.input_type == "LIST") {
            let values = value.map((item) => item.name);
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              values,
              action.selectField
            );
          } else {
            this.setValuesToDataTable(
              applyOnPopup,
              rowIndex,
              value,
              action.selectField
            );
          }
          break;

        case "SHOW":
          this.showTemplateFields.push(action.selectField + rowIndex);
          break;
        case "HIDE":
          if (hideIndex == -1) {
            this.setValuesToDataTable(false, rowIndex, "", action.selectField);
            if (
              !this.hideFields.includes(action.selectedField + "$" + rowIndex)
            ) {
              this.hideFields.push(action.selectField + "$" + rowIndex);
            }
          }
          break;
        case "DISABLE":
          if (disabledIndex == -1) {
            this.disabledFields.push(action.selectField + rowIndex);
          }
          break;
      }
    },
    setValuesToDataTable(applyOnPopup, rowIndex, value, field) {
      if (applyOnPopup) {
        this.$set(
          this.splitedDatatableRows[this.dataTableData?.[rowIndex]?.keyIndex],
          field,
          value
        );
      } else {
        this.$set(this.rowsData[rowIndex].form, field, value);
      }
    },
    removeActionsOnDataTable(actions, rowIndex) {
      actions.forEach((action) => {
        let hideIndex = this.hideFields.findIndex(
          (e) => e == action.selectField + "$" + rowIndex
        );
        //  let showIndex = this.hideFields.findIndex(e => e == action.selectField);
        let disabledIndex = this.disabledFields.findIndex(
          (e) => e == action.selectField + rowIndex
        );
        // let updateValue = "";
        // if (action.data_type == "NUMBER") {
        //   updateValue = 0;
        // }
        switch (action.operator_type) {
          // case "UPDATE":

          //   this.$set(this.form, action.selectField, updateValue);
          //   console.log("updateValue");
          //   break;

          case "HIDE":
            if (hideIndex > -1) {
              this.hideFields.splice(hideIndex, 1);
            }
            break;

          case "SHOW":
            if (
              !this.hideFields.includes(action.selectField + "$" + rowIndex)
            ) {
              this.hideFields.push(action.selectField + "$" + rowIndex);
            }

            break;
          case "DISABLE":
            if (disabledIndex > -1) {
              this.disabledFields.splice(disabledIndex, 1);
            }
            break;
        }
      });
    },
    // Define the value to be removed from the hideFields array
    async applyRulesOnFields(fields, rules, form, performSaveActions = false) {
      this.showTemplateFields = [];
      this.hideFields = [];
      this.hideOptions = {};
      this.disabledFields = [];
      await rules.map((rule) => {
        if (rule.isRuleActive) {
          let results = [];
          if (rule?.show_alert || rule?.when == "AFTER_SAVE") {
            if (performSaveActions) {
              rule.conditions.forEach((condition) => {
                if (
                  condition.selectField &&
                  !condition.selectField.includes("#")
                ) {
                  results.push(this.checkCondition(condition, form, fields));
                }
              });
              if (rule.query_type == "AND" && results.every((el) => el)) {
                this.applyActions(
                  rule.actions,
                  form,
                  fields,
                  rule.conditions,
                  rule
                );
              } else if (rule.query_type == "OR" && results.some((el) => el)) {
                this.applyActions(
                  rule.actions,
                  form,
                  fields,
                  rule.conditions,
                  rule
                );
              } else {
                this.removeActions(rule.actions, fields);
                // this.removeActions(rule.actions.filter((action) => this.hideFields.includes(action.selectField)));
              }
            }
            return;
          }
          rule.conditions.forEach((condition) => {
            if (condition.selectField) {
              results.push(this.checkCondition(condition, form, fields));
            }
          });

          if (rule.query_type == "AND" && results.every((el) => el)) {
            this.applyActions(
              rule.actions,
              form,
              fields,
              rule.conditions,
              rule
            );
          } else if (rule.query_type == "OR" && results.some((el) => el)) {
            this.applyActions(
              rule.actions,
              form,
              fields,
              rule.conditions,
              rule
            );
          } else {
            this.removeActions(rule.actions, fields);
            // this.removeActions(rule.actions.filter((action) => this.hideFields.includes(action.selectField)));
          }
        } else {
          return;
        }
      });
      // this.refresh = true;
      //  setTimeout(() => { fields
      //    this.refresh = false;
      //  });
    },

    removeActions(actions, fields) {
      actions.forEach((action) => {
        let hideIndex = this.hideFields.findIndex(
          (e) => e == action.selectField
        );
        //  let showIndex = this.hideFields.findIndex(e => e == action.selectField);
        let disabledIndex = this.disabledFields.findIndex(
          (e) => e == action.selectField
        );
        // let updateValue = "";
        // if (action.data_type == "NUMBER") {
        //   updateValue = 0;
        // }
        switch (action.operator_type) {
          // case "UPDATE":

          //   this.$set(this.form, action.selectField, updateValue);
          //   console.log("updateValue");
          //   break;
          // case "changelabel":
          //   if (action.selectField && this.labelsData[action.selectField]) {
          //     delete this.labelsData[action.selectField];
          //   }
          //   break;

          case "CHANGE_COLOR":
            if (this.colorFields) {
              if (this.colorFields[action.selectField]) {
                if (
                  this.colorFields[action.selectField][action.property_type]
                ) {
                  this.$set(this.colorFields, action.selectField, {});
                }
              }
            }
            break;
          case "HIDE":
            if (hideIndex > -1) {
              this.hideFields.splice(hideIndex, 1);
            }
            break;

          case "SHOW": {
            const matchingFields = fields
              .filter((field) =>
                field.is_in_division?.includes(action.selectField)
              )
              .map((field) => field.key);
            if (matchingFields.length > 0) {
              this.hideFields.push(...matchingFields, action.selectField);
            } else {
              this.hideFields.push(action.selectField);
            }
            break;
          }
          case "DISABLE":
            if (disabledIndex > -1) {
              this.disabledFields.splice(disabledIndex, 1);
            }
            break;
          case "HIDE_OPTIONS":
            this.hideOptions[action.selectField] = [];
            break;
        }
      });
    },
    applyActions(actions, form, fields, conditions, rule) {
      actions.forEach((action) => {
        this.applySingleCondition(action, form, fields, conditions, rule);
      });
    },
    applySingleCondition(action, form, fields, conditions, rule) {
      if (action.selectField == "NOTIFICATION") {
        let currentForm = {};
        conditions.forEach((e) => {
          if (e?.selectField && e.data_source == "TEMPLATE") {
            this.$set(currentForm, e.selectField, form[e.selectField]);
          }
        });
        let k = action.message.trim().toLowerCase().replaceAll(" ", "");
        if (
          (action?.show_alert_once &&
            JSON.stringify(this.rulesData[k]) == JSON.stringify(currentForm)) ||
          this.isView
        ) {
          return;
        }
        this.$set(this.rulesData, k, currentForm);
        if (action?.message) {
          if (action.data_source === "Value") {
            const messageType = {
              message: "info",
              success: "success",
              warning: "warning",
              error: "error",
            }[action.notify_type];

            this.$message({
              message: action.message + " " + action.value,
              type: messageType,
            });
          } else if (action.data_source === "Template") {
            const messageType = {
              message: "info",
              success: "success",
              warning: "warning",
              error: "error",
            }[action.notify_type];

            const value =
              form[action.value] !== undefined ? form[action.value] : "";

            this.$message({
              message: action.message + " " + value,
              type: messageType,
            });
          } else {
            this.$message({
              message: action.message,
              type: action.notify_type,
            });
          }
        }
        return;
      }
      let value = this.fetchFieldValue(action, form, fields);
      switch (action.operator_type) {
        case "CHANGE_COLOR":
          if (this.colorFields) {
            if (this.colorFields[action.selectField]) {
              this.colorFields[action.selectField] = {
                ...this.colorFields[action.selectField],
                ...{
                  [action.property_type]: action.color,
                },
              };
            } else {
              this.$set(this.colorFields, action.selectField, {
                [action.property_type]: action.color,
              });
            }
          } else {
            this.$set(this.colorFields, action.selectField, {
              [action.property_type]: action.color,
            });
          }

          break;

        case "UPDATE_PROPERTY":
          if (action.input_type == "CHECKBOX_GROUP") {
            if (action.property == "MAX") {
              this.$set(this.form, action.selectField + "/max_value", value);
            } else if (action.property == "MIN") {
              this.$set(this.form, action.selectField + "/min_value", value);
            }
          }
          break;
        case "min_max":
          if (
            (action.input_type === "NUMBER"|| action.data_type=='NUMBER') &&
            action.operator_type === "min_max"
          ) {
            const fieldValue = parseFloat(form[action.selectField]);

            const minValue = parseFloat(action.startValue) || -Infinity;
            const maxValue = parseFloat(action.endValue) || Infinity;

            if (!isNaN(fieldValue)) {
              if (fieldValue < minValue) {
                form[action.selectField] = minValue;
                this.$message.info(
                  `The entered value ${fieldValue} is below the minimum allowed value ${minValue}.`
                );
              } else if (fieldValue > maxValue) {
                form[action.selectField] = maxValue;
                this.$message.info(
                  `The entered value ${fieldValue} exceeds the maximum allowed value ${maxValue}.`
                );
              } else {
                console.log("Value is within the acceptable range.");
              }
            } else {
              console.log("Invalid value entered.");
            }
          }
          break;

        case "INCREMENT":
          if (action.selectField && action.data_source == "TEMPLATE") {
            let selectedFieldValue = this.form[action.selectField] || 0;
            let templateFieldValue = this.form[action.value] || 0;
            let sumValue =
              parseFloat(selectedFieldValue) + parseFloat(templateFieldValue);
            ("64bd02fee670b15d7af19ee7");
            // this.form[action.selectField] = sumValue;
            this.$set(this.form, action.selectField, sumValue);
          } else if (action.selectField && action.data_source === "VALUE") {
            const currentValue = this.form[action.selectField] || 0;
            const valueToAdd = value || 0;
            this.$set(
              this.form,
              action.selectField,
              parseFloat(currentValue) + parseFloat(valueToAdd)
            );
          }
          break;
        case "DECREMENT":
          if (action.selectField && action.data_source == "TEMPLATE") {
            let selectedFieldValue = this.form[action.selectField] || 0;
            let templateFieldValue = this.form[action.value] || 0;
            let sumValue =
              parseFloat(selectedFieldValue) - parseFloat(templateFieldValue);
            ("64bd02fee670b15d7af19ee7");
            // this.form[action.selectField] = sumValue;
            this.$set(this.form, action.selectField, sumValue);
          } else if (action.selectField && action.data_source === "VALUE") {
            const currentValue = this.form[action.selectField] || 0;
            const valueToAdd = value || 0;
            this.$set(
              this.form,
              action.selectField,
              parseFloat(currentValue) - parseFloat(valueToAdd)
            );
          }
          break;
        case "MULTIPLY":
          if (action.selectField && action.data_source == "TEMPLATE") {
            let selectedValue = this.form[action.selectField] || 0;
            let templateValue = this.form[action.value] || 0;
            let mulValue = parseInt(selectedValue) * parseInt(templateValue);
            this.$set(this.form, action.selectField, mulValue);
          } else if (action.selectField && action.data_source === "VALUE") {
            const currentValue = this.form[action.selectField] || 0;
            const valueToMul = value || 0;
            this.$set(
              this.form,
              action.selectField,
              parseFloat(currentValue) * parseFloat(valueToMul)
            );
          }
          break;
        case "Make_this_field_required":
          fields = fields.map((item) => {
            if (item.key == action.selectField) {
              item.validations.required = true;
            }
          });
          break;
        case "Make_this_field_unrequired":
          fields = fields.map((item) => {
            if (item.key == action.selectField) {
              item.validations.required = false;
            }
          });
          break;
        case "changelabel":
          if (
            (action.input_type == "YES_OR_NO" ||
              action.input_type == "CHECKBOX" ||
              action.input_type == "RADIO") &&
            action.operator_type == "changelabel" &&
            action.value1
          ) {
            this.labelsData[action.selectField] = action.value1;
          } else if (action.selectField && fields) {
            this.labelsData[action.selectField] = action.value;
          }
          break;
        case "empty_field":
          if (action.selectField) {
            if (action.selectField.includes("#")) {
              let [tableKey, key] = action.selectField.split("#");
              let dataTableData = form[tableKey] || [];
              dataTableData = dataTableData.map((fl) => {
                let results = [];
                conditions.forEach((con) => {
                  if (con.selectField && con.selectField.includes("#")) {
                    let [t, k] = con.selectField.split("#");
                    let newCon = { ...con, ...{ ["selectField"]: k }, t: t };
                    results.push(
                      this.checkCondition(newCon, { ...fl, ...form }, fields)
                    );
                  }
                });
                if (
                  conditions &&
                  conditions.length &&
                  ((rule?.query_type == "AND" && results.every((el) => el)) ||
                    (rule?.query_type == "OR" && results.some((el) => el)))
                ) {
                  fl[key] = "";
                }
                return fl;
              });
              this.$set(this.form, tableKey, dataTableData);
              return;
            } else {
              for (const field of fields) {
                if (
                  field.input_type == "ENTITY_VARIABLE" &&
                  field.relationship_key &&
                  field.relationship_key == action.selectField
                ) {
                  this.$set(this.form, field.key, null);
                  this.$set(this.form, action.selectField, null);
                } else if (field.input_type == "CHECKBOX_GROUP") {
                  this.$set(this.form, action.selectField, []);
                } else {
                  this.$set(this.form, action.selectField, null);
                }
              }
            }
          }
          break;
        case "PUSH":
          if (
            action.input_type == "WEEKDAYS" ||
            action.input_type == "MULTI_SELECT"
          ) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              // this.$set(targetForm, action.selectField, [...targetForm[action.selectField],...action.value]);
              // console.log("action.value",targetForm[action.selectField])
              action.value.map((opt) => {
                if (!targetForm?.[action.selectField].includes(opt)) {
                  targetForm[action.selectField].push(opt);
                }
              });
            }
          }
          break;
        case "PULL":
          if (
            action.input_type == "WEEKDAYS" ||
            action.input_type == "MULTI_SELECT"
          ) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              let removeIndices = [];
              action.value.map((opt) => {
                if (targetForm?.[action.selectField]?.includes(opt)) {
                  removeIndices.push(
                    targetForm?.[action.selectField].indexOf(opt)
                  );
                }
              });
              if (removeIndices.length) {
                removeIndices.sort((a, b) => b - a);
                removeIndices.map((index) => {
                  targetForm[action.selectField].splice(index, 1);
                });
              }
            }
          }
          break;
        case "HIDE_OPTIONS":
          if (["SELECT", "MULTI_SELECT"].includes(action.input_type)) {
            this.hideOptions[action.selectField] = [...action.value];
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            let removeIndices = [];
            action.value.map((v, i) => {
              if (targetForm?.[action.selectedField]?.includes(v)) {
                removeIndices.push(i);
              } else if (targetForm?.[action.selectField] == v) {
                targetForm[action.selectField] = "";
              }
            });
            if (removeIndices.length) {
              removeIndices.sort((a, b) => b - a);
              removeIndices.map((index) => {
                targetForm?.[action.selectField].splice(index, 1);
              });
            }
          }
          break;
        case "UPDATE":
          // if (action.input_type === "CHECKBOX_GROUP" ) {
          //   const selectedGroupKey = action.selectField; // Assuming action.group_key contains the desired group key value

          //   const filteredFields = fields.filter(
          //     (field) =>
          //       field.inputType === "CHECKBOX" &&
          //        field.group_key === selectedGroupKey
          //   );
          //   console.log("Selected Group Key:", fields);
          //   (filteredFields || []).forEach((checkBox) => {
          //     if (checkBox?.key && value.indexOf(checkBox.key) != -1) {
          //       this.$set(this.form, checkBox.key, true);
          //     } else {
          //       this.$set(this.form, checkBox.key, false);
          //     }
          //   });
          //   // Perform further operations with the filteredFields array
          //  }

          if (
            action?.data_source == "TEMPLATE" &&
            action?.data_type == "OBJECT_ID"
          ) {
            this.$set(this.form, action.selectField, form[action.value]);
          } else {
            this.$set(this.form, action.selectField, value);
          }
          if (action.selectField && action.selectField.includes("#")) {
            let [tableKey, key] = action.selectField.split("#");
            let dataTableData = form[tableKey] || [];
            dataTableData = dataTableData.map((fl) => {
              let results = [];
              conditions.forEach((con) => {
                if (con.selectField && con.selectField.includes("#")) {
                  let [t, k] = con.selectField.split("#");
                  let newCon = { ...con, ...{ ["selectField"]: k }, t: t };
                  results.push(
                    this.checkCondition(newCon, { ...fl, ...form }, fields)
                  );
                }
              });
              if (
                conditions &&
                conditions.length &&
                ((rule?.query_type == "AND" && results.every((el) => el)) ||
                  (rule?.query_type == "OR" && results.some((el) => el)))
              ) {
                if (
                  action.input_type == "SELECT" &&
                  typeof value == "object" &&
                  value.length
                ) {
                  fl[key] = value[0];
                } else {
                  fl[key] = value;
                }
                // changed = true;
              }
              return fl;
            });
            bus.$emit("data_table_update", {
              key: tableKey,
              data: dataTableData,
            });
            // if(changed){
            //   // this.$set(this.form, tableKey, dataTableData);
            //   bus.$emit("data_table_update", {
            //     key: tableKey,
            //     data: dataTableData,
            //   });
            // }
          } else if (
            action.selectField &&
            action.operator_type === "UPDATE" &&
            action.data_source === "TEMPLATE" &&
            action.value.includes("#")
          ) {
            let [t, k] = action.value.split("#");
            let arr = form[t] || [];
            let p = arr
              .map((item) => item[k])
              .filter((value) => value !== undefined);
            if (p.length > 0) {
              if (action.selectedRowIndex === "first_row") {
                form[action.selectField] = p[0];
              } else if (action.selectedRowIndex === "last_row") {
                form[action.selectField] = p[p.length - 1];
              }
            }
          } else if (action.is_fixed == true && action.input_type == "TIME") {
            this.$set(this.form, action.selectField, action.value);
          } else if (
            action.input_type == "TIME" &&
            action.data_source == "CURRENTIME"
          ) {
            const formattedValue = moment().format("HH:mm:ss");
            this.$set(this.form, action.selectField, formattedValue);
          } else if (
            action.input_type == "DATE_TIME" &&
            action.data_source == "TODAY" &&
            action.data_type == "DATE_TIME"
          ) {
            const formattedValue = moment().toISOString();
            this.$set(this.form, action.selectField, formattedValue);
          } else if (
            action.data_type == "DATE" &&
            action.data_source == "TODAY"
          ) {
            const formatedValue = moment().startOf("day").toISOString();
            this.$set(this.form, action.selectField, formatedValue);
          } else if (action.input_type === "CHECKBOX_GROUP") {
            const filteredFields = fields.filter(
              (field) =>
                field.inputType === "CHECKBOX_GROUP" &&
                field.input_type === "CHECKBOX_GROUP"
            );

            (filteredFields || []).forEach((checkBox) => {
              const optionsArray = checkBox.options || [];
              const selectedOptions = action.value || [];
              const selectedCount = selectedOptions.length;
              if (
                selectedCount >= checkBox.min_selection &&
                selectedCount <= checkBox.max_selection
              ) {
                const isMatchFound = optionsArray.some((option) => {
                  const isMatch = selectedOptions.includes(option);
                  if (isMatch) {
                    const targetForm = this.form.require
                      ? this.form.require[0]
                      : this.form;
                    if (targetForm) {
                      this.$set(
                        targetForm,
                        action.selectField,
                        selectedOptions
                      );
                    }
                  }
                });
                console.log("ISMATCH", isMatchFound);
              } else {
                this.$message.error(
                  "Updated options count not within allowed range"
                );
              }
            });
          } else if (action.selectField === "entity_variable") {
            const filterField = fields.filter(
              (field) =>
                field.input_type === "ENTITY_VARIABLE" &&
                field.field_assignable === "read_only"
            );

            if (filterField.length > 0) {
              this.$notify({
                title: "Error",
                message:
                  "The selected field is in read-only state. Update not allowed.",
                type: "error",
              });
            } else {
              const targetForm = this.form.require
                ? this.form.require[0]
                : this.form;
              if (targetForm) {
                this.$set(targetForm, action.selectField, action.value);
              }
            }
          }else if (
            (action?.input_type == "DATE" ||
              action?.data_type == "NUMBER" ||
              action?.data_type == "STRING") &&
            action.value &&
            action.data_source == "TEMPLATE"
          ) {
            const selectFieldValue = action.value;
            const objectKey = `${selectFieldValue}/name`;
            const employeeId = form[objectKey];
            if (employeeId && typeof employeeId === 'string' && employeeId.match(/\d{2}-\d{2}-\d{4}/)) {
              this.$set(this.form, action.selectField, employeeId);
            } else {
              this.$set(this.form, action.selectField, this.form[action.value]);
            }
          }
           else if (
            action.input_type === "MULTI_SELECT" ||
            action.input_type == "WEEKDAYS" ||
            action.input_type == "DATE_TIME_RANGE" ||
            action.input_type == "DATE_RANGE" ||
            action.input_type == "DATE" ||
            (action.input_type == "TIME_RANGE" &&
              action.value &&
              action.value.length)
          ) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              this.$set(targetForm, action.selectField, action.value);
            }
          } else if (
            action.data_type === "NUMBER" &&
            action.value &&
            action.operator !== "between" &&
            !action.selectField.includes("#")
          ) {
            let validationFailed = false;

            for (const field of fields) {
              if (
                (field.input_type === "NUMBER" ||
                  field.input_type === "CURRENCY") &&
                ((field.min_value !== null && field.min_value !== undefined) ||
                  (field.max_value !== null &&
                    field.max_value !== undefined)) && // Check if min_value or max_value is defined
                ((field.min_value !== null &&
                  field.min_value !== undefined &&
                  action.value < field.min_value) ||
                  (field.max_value !== null &&
                    field.max_value !== undefined &&
                    action.value > field.max_value))
              ) {
                const minValue = field.min_value;
                const maxValue = field.max_value;

                let errorMessage = `Error for ${action.selectField}: The updated value should`;
                if (minValue !== undefined && minValue !== null) {
                  errorMessage += ` be greater than or equal to ${minValue}`;
                }
                if (
                  (minValue !== undefined &&
                    maxValue !== undefined &&
                    maxValue !== null) ||
                  (minValue !== null && maxValue !== null)
                ) {
                  errorMessage += " and";
                }
                if (maxValue !== undefined && maxValue !== null) {
                  errorMessage += ` be less than or equal to ${maxValue}`;
                }

                Notification.error({
                  title: "Validation Error",
                  message: errorMessage,
                });

                validationFailed = true;
                break;
              }
            }

            if (!validationFailed) {
              const targetForm = this.form.require
                ? this.form.require[0]
                : this.form;
              if (targetForm) {
                this.$set(targetForm, action.selectField, action.value);
              }
            }
          } else if (action.input_type == "CHECKBOX" && action.value) {
            const targetForm = this.form.require
              ? this.form.require[0]
              : this.form;
            if (targetForm) {
              this.$set(targetForm, action.selectField, action.value);
            }
          } else if (
            action.selectField == "time" &&
            action.input_type == "TIME"
          ) {
            const dateObject = new Date(action.value);
            const timeString = dateObject.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            });
            this.$set(this.form, action.selectField, timeString);
          } else if (
            action.selectField == "fixed_time" &&
            action.input_type == "TIME"
          ) {
            const dateObject = new Date(action.value);
            const timeString = dateObject.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
            this.$set(this.form, action.selectField, timeString);
          } else if (action.data_source == "PAYMENT") {
            let keys = action.value.split("#");
            if (
              this.form &&
              keys[0] &&
              keys[1] &&
              this.form[keys[0] + "_info"] &&
              this.form[keys[0] + "_info"][keys[1]]
            ) {
              this.$set(
                this.form,
                action.selectField,
                this.form[keys[0] + "_info"][keys[1]]
              );
            }
          } else if (
            action.data_source == "login_user" &&
            action.value &&
            this.getAuthenticatedUser[action.value]
          ) {
            this.$set(
              this.form,
              action.selectField,
              this.getAuthenticatedUser[action.value]
            );
          } else if (action.input_type === "LIST") {
            action.value.forEach((item) => {
              let fieldName = action.selectField;
              let infoFieldName = action.selectField + "_info";
              this.$set(this.form, fieldName, item.split("_")[0]);
              this.$set(this.form, infoFieldName, item.split("_")[1]);
            });
          } else {
            if (action.selectField && action.selectField.includes("#")) {
              let [tableKey, key] = action.selectField.split("#");
              let dataTableData = form[tableKey] || [],
                changed = false;
              dataTableData = dataTableData.map((fl) => {
                let results = [];
                conditions.forEach((con) => {
                  if (con.selectField && con.selectField.includes("#")) {
                    let [t, k] = con.selectField.split("#");
                    // console.log("t", t, k);
                    let newCon = { ...con, ...{ ["selectField"]: k }, t: t };
                    results.push(this.checkCondition(newCon, fl, fields));
                  }
                });
                if (
                  conditions &&
                  conditions.length &&
                  results.every((e) => e)
                ) {
                  changed = true;
                  if (
                    action.input_type == "SELECT" &&
                    typeof value == "object" &&
                    value.length
                  ) {
                    fl[key] = value[0];
                  } else {
                    fl[key] = value;
                  }
                }
                return fl;
              });
              if (changed) {
                this.$set(this.form, tableKey, dataTableData);
              }
            } else {
              this.$set(this.form, action.selectField, String(value));
            }
          }
          break;
        case "SHOW":
          {
            this.hideFields = this.hideFields.filter(
              (e) => e != action.selectField
            );
            const matchingField = fields.find(
              (field) => field.key === action.selectField
            );

            if (matchingField) {
              matchingField.properties.filed_content = false;
            }
          }

          break;
        case "HIDE": {
          const matchingFields = fields
            .filter((field) =>
              field.is_in_division?.includes(action.selectField)
            )
            .map((field) => field.key);

          if (matchingFields.length > 0) {
            this.hideFields.push(...matchingFields, action.selectField);
          } else {
            this.hideFields.push(action.selectField);
          }
          break;
        }
        case "ENABLE":
          {
            this.disabledFields = this.disabledFields.filter(
              (e) => e !== action.selectField
            );
            const matchingField = fields.find(
              (field) => field.key === action.selectField
            );

            if (matchingField) {
              matchingField.properties.filed_content = false;
            }
          }
          break;

        case "DISABLE":
          this.disabledFields.push(action.selectField);
          break;
      }
    },
    checkCondition(condition, form, fields, conditions = []) {
      if (condition?.selectField == "compare_slots") {
        let checkDate;
        if (form.currentColumnDate) {
          let [m, d] = form.currentColumnDate.split("-");
          checkDate = new Date(
            new Date(this.currentDate).getFullYear(),
            m - 1,
            d
          );
          if (checkDate > new Date()) {
            return false;
          }
        }
        if (!form.allSlots || !form.allSlots.length) {
          return false;
        }
        if (form[condition.frequency] && form[condition.start_date]) {
          if (new Date(form[condition.start_date]) > checkDate) {
            return false;
          }
          if (form.allSlots && form.currentColumnDate) {
            let found = form.allSlots.find((f) => {
              if (
                checkDate >= new Date(f.start) &&
                checkDate < new Date(f.end)
              ) {
                return true;
              }
            });
            return found && found.found
              ? condition.operator == "in"
              : condition.operator == "nin";
          }
        }
        return condition.operator == "nin";
      }
      if (condition?.linked_to_slots) {
        let selectedRule = conditions.find(
          (rule) => rule.selectField == "compare_slots"
        );
        let divideDataBy = fields.find((e) => condition.selectField == e.key);
        if (["APPROVED", "PENDING"].includes(condition.operator)) {
          divideDataBy = fields.find((e) => e.divide_data_by);
        }
        if (
          selectedRule &&
          divideDataBy?.linked_entity_id &&
          form["action/" + form.currentColumnDate + "/" + condition.column] &&
          form?.row_data?.[
            divideDataBy.linked_entity_id +
              "#" +
              divideDataBy.template_id +
              "#" +
              (divideDataBy.divide_data_by
                ? divideDataBy.divide_data_by
                : divideDataBy.key)
          ]
        ) {
          let data = (
            form.row_data[
              divideDataBy.linked_entity_id +
                "#" +
                divideDataBy.template_id +
                "#" +
                (divideDataBy.divide_data_by
                  ? divideDataBy.divide_data_by
                  : divideDataBy.key)
            ] || []
          ).find((e) => {
            return (
              e["action/" + condition.column] ==
                form[
                  "action/" + form.currentColumnDate + "/" + condition.column
                ] ||
              e._id ==
                form[
                  "action/" + form.currentColumnDate + "/" + condition.column
                ]
            );
          });
          if (["APPROVED", "PENDING"].includes(condition.operator)) {
            if (condition.operator == "APPROVED") {
              return data?.approvalStatus == "ACTIVE";
            }
            return (
              (data?.approvalStatus == "ARCHIVED") &
              data?.isCreatedFromFormbuilder
            );
          }
          if (data) {
            form[condition.selectField] = data[condition.selectField];
          }
        }
      }

      let value = this.fetchFieldValue(condition, form, fields, true);

      let value1, value2;
      if (
        condition.data_type === "OBJECT_ID" &&
        condition.data_source === "TEMPLATE" &&
        condition.input_type === "ENTITY"
      ) {
        const selectFieldValue = form[condition.selectField];
        const objectKey = `${condition.selectField}/name`;
        const employeeId = form[objectKey]
          ? selectFieldValue
          : form[condition.value];

        value1 = employeeId;
      } else if (condition.input_type == "RADIO") {
        let field = (fields || []).find((e) => e.key == condition.selectField);
        value1 = field?.label ? field.label : condition.value;
      } else if (condition.operator === "labelmatch") {
        let field = fields.find(
          (e) => e.key === condition.selectField && e.label === condition.value
        );
        return (
          field || this.labelsData[condition.selectField] === condition.value
        );
      } else if (
        condition.input_type == "CHECKBOX_GROUP" &&
        condition.is_individual &&
        condition.value
      ) {
        value1 = condition.value.filter((e) => form[e]);
      } else if (
        condition.input_type == "DATE" &&
        condition.data_source == "TODAY" &&
        condition.selectField
      ) {
        value1 = form[condition.selectField];
      } else if (
        condition.data_type == "QUESTION" &&
        condition.operator == "YES"
      ) {
        return form[condition.selectField] == condition.operator;
      } else if (
        condition.data_type == "QUESTION" &&
        condition.operator == "NO"
      ) {
        return form[condition.selectField] == condition.operator;
      } else if (
        condition.data_source == "login_user" &&
        condition.value &&
        this.getAuthenticatedUser[condition.value]
      ) {
        return (
          form[condition.selectField] ==
          this.getAuthenticatedUser[condition.value]
        );
      } else if (condition.input_type === "LIST") {
        const conditionValue = condition.value[0];
        const formValue = `${form[condition.selectField]}_${
          form[condition.selectField + "_info"]
        }`;
        value1 = form[condition.selectField];
        if (conditionValue === formValue) {
          return true;
        }
        value1 = form[condition.selectField];
      } else if (
        condition.data_type == "userType" &&
        condition.value &&
        condition.value.length
      ) {
        return condition.value.includes(
          this.getAuthenticatedUser?.activeRole?.userType_id
        );
      } else if (
        this.getAuthenticatedUser &&
        condition.data_source === "login" &&
        condition.selectField !== "apply_rules"
      ) {
        const authenticatedUserId = this.getAuthenticatedUser._id;

        if (
          (condition.data_type === "created_by" &&
            this.form.created_by === authenticatedUserId) ||
          (condition.data_type === "updated_by" &&
            this.form.updated_by === authenticatedUserId)
        ) {
          return true;
        }
      } else if (
        condition.data_type == "created_by" &&
        condition.value &&
        condition.value.length
      ) {
        return condition.value == this.form.created_by;
      } else if (
        condition.data_type == "updated_by" &&
        condition.value &&
        condition.value.length
      ) {
        return condition.value == this.form.updated_by;
      } else if (
        condition.data_type == "created_at" &&
        condition.value &&
        condition.value.length &&
        condition.operator == "<"
      ) {
        return condition.value < this.form.created_at;
      } else if (
        condition.data_type == "updated_at" &&
        condition.value &&
        condition.value.length &&
        condition.operator == "<"
      ) {
        return condition.value < this.form.updated_at;
      } else if (
        condition.data_type == "IsUserType" &&
        condition.operator != "nin" &&
        (condition.value == "ApplicationUser" ||
          condition.value == "ApplicationUser-Entities")
      ) {
        if (
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.is_contact &&
          this.getAuthenticatedUser.is_contact == true
        ) {
          if (
            condition?.application_user_types &&
            condition.application_user_types.length
          ) {
            if (
              this.getActiveContactType?.contact_type?._id &&
              condition.application_user_types.indexOf(
                this.getActiveContactType.contact_type._id
              ) !== -1
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        }
        return false;
      } else if (
        condition.data_type == "IsUserType" &&
        condition.value == "CompanyUser"
      ) {
        if (this.getAuthenticatedUser?.activeRole?.userType_name == "OWNER") {
          return false;
        } else if (this.getAuthenticatedUser.is_contact) {
          return false;
        } else {
          return true;
        }
      } else if (condition.selectField === "apply_rules") {
        if (condition.value === "Entities") {
          return this.$route.path.includes("/entity/edit");
        } else if (condition.value === "FormBuilders") {
          if (condition.formbuilderId) {
            let formbuilder_id = this.formbuilderDetails?._id;
            return (
              this.$route.path.includes("fb") &&
              formbuilder_id == condition.formbuilderId
            );
          } else {
            return this.$route.path.includes("fb");
          }
        } else if (condition.value === "Both") {
          return (
            this.$route.path.includes("/entity/edit") ||
            this.$route.path.includes("fb")
          );
        }
      } else if (
        condition.data_source === "TODAY" &&
        condition.result_type !== "DATE" &&
        form[condition.selectField]
      ) {
        return (
          form[condition.selectField] >=
            moment().startOf("day").toISOString() &&
          form[condition.selectField] <= moment().endOf("day").toISOString()
        );
      } else if (condition.operator === "range") {
        return (
          form[condition.selectField] == value >= value1 && value1 <= value2
        );
      } else {
        let v;
        if (condition.selectField.includes("#")) {
          let [d, k] = condition.selectField.split("#");
          let dataTableData = form[d] || [];
          if (dataTableData.length) {
            let result = [];
            dataTableData.forEach((dt) => {
              let vd;
              if (dt && dt[k]) {
                vd = dt[k];
              }
              if (
                condition.input_type == "FORMULA" &&
                condition.data_type == "DATE"
              ) {
                vd = new Date(vd);
              }
              let r = this.checkOperator(value, vd, condition);
              result.push(r);
            });
            return result.some((e) => e);
          }
          return false;
        } else if (form && form.require && form.require[0]) {
          v =
            form[condition.selectField] ||
            form.require[0][condition.selectField];
        } else {
          v = form[condition.selectField];
        }

        if (
          condition.input_type == "FORMULA" &&
          condition.data_type == "DATE"
        ) {
          v = new Date(v);
        }
        value1 = this.comparisonMethod(v, condition.input_type);
      }

      return this.checkOperator(value, value1, condition);
    },
    checkOperator(value, value1, condition) {
      let result = false;
      let start = condition.startValue;
      let end = condition.endValue;
      switch (condition.operator) {
        case ">":
          if (
            condition.data_type == "DATE" &&
            condition.result_type != "DATE" && condition.data_source!='TODAY'
          ) {
            const dateValue1 = new Date(value1);
            const dateValueInMilliseconds = dateValue1.getTime();
            const dateToCompareInMilliseconds = new Date(value).getTime();
            result = dateValueInMilliseconds > dateToCompareInMilliseconds;
          } 
          else  if (
            condition.data_type === "DATE" &&
            condition.result_type !== "DATE" && condition.data_source=='TODAY'
        ) {
            const dateValue1 = new Date(value1);
            const dateValueInMilliseconds = dateValue1.getTime();
            const dateToCompare = new Date(value);
            dateToCompare.setHours(0, 0, 0, 0); 
            const dateToCompareInMilliseconds = dateToCompare.getTime();
            result = dateValueInMilliseconds > dateToCompareInMilliseconds;
        }
          else if (
            condition.data_type === "DATE" &&
            condition.result_type == "DATE"
          ) {
            const dateValue1InMilliseconds = new Date(value1).getTime();
            const dateValueInMilliseconds = new Date(value).getTime();

            result = dateValue1InMilliseconds > dateValueInMilliseconds;
          } else if (condition.data_type == "DATE_TIME") {
            const date1 = new Date(value1);
            const date2 = new Date(value);
            const istOffset = 5.5 * 60 * 60 * 1000; 
            const date1IST = new Date(date1.getTime() + istOffset);
            const date2IST = new Date(date2.getTime() + istOffset);
            result = date1IST.getTime() > date2IST.getTime();
          } else {
            result =
              value1 && parseInt(value1) > parseInt(value) ? true : false;
          }
          break;

        case "<":
          if (
            condition.data_type == "DATE" &&
            condition.result_type !== "DATE" && condition.data_source!='TODAY'
          ) {
            const dateValue1 = new Date(value1);
            const dateValueInMilliseconds = dateValue1.getTime();
            const dateToCompareInMilliseconds = new Date(value).getTime();
            result = dateValueInMilliseconds < dateToCompareInMilliseconds;
          } 
         else  if (
            condition.data_type === "DATE" &&
            condition.result_type !== "DATE" && condition.data_source=='TODAY'
        ) {
            const dateValue1 = new Date(value1);
            const dateValueInMilliseconds = dateValue1.getTime();
            const dateToCompare = new Date(value);
            dateToCompare.setHours(0, 0, 0, 0); 
            const dateToCompareInMilliseconds = dateToCompare.getTime();
            result = dateValueInMilliseconds < dateToCompareInMilliseconds;
        }
        
          else if (
            condition.data_type === "DATE" &&
            condition.result_type == "DATE"
          ) {
          
            const dateValue1InMilliseconds = new Date(value1).getTime();
            const dateValueInMilliseconds = new Date(value).getTime();

            result = dateValue1InMilliseconds < dateValueInMilliseconds;
          } else if (condition.data_type == "DATE_TIME") {
            const date1 = new Date(value1);
            const date2 = new Date(value);
            const istOffset = 5.5 * 60 * 60 * 1000; 
            const date1IST = new Date(date1.getTime() + istOffset);
            const date2IST = new Date(date2.getTime() + istOffset);
            result = date1IST.getTime() < date2IST.getTime();
          } else {
            result = parseInt(value1) < parseInt(value);
          }
          break;
        case ">=":
          if (
            condition.data_type == "DATE" &&
            condition.data_source == "TODAY" &&
            condition.result_type != "DATE"
          ) {
            const dateValue1 = new Date(value1);
            const dateValue2 = new Date(value);

            dateValue1.setHours(0, 0, 0, 0);
            dateValue2.setHours(0, 0, 0, 0);
            result = dateValue1.getTime() >= dateValue2.getTime();
          } else if (
            condition.data_type === "DATE" &&
            condition.result_type == "DATE"
          ) {
            const dateValue1InMilliseconds = new Date(value1).getTime();
            const dateValueInMilliseconds = new Date(value).getTime();

            result = dateValue1InMilliseconds >= dateValueInMilliseconds;
          } else if (condition.data_type == "DATE_TIME") {
            const date1 = new Date(value1);
            const date2 = new Date(value);
            const istOffset = 5.5 * 60 * 60 * 1000;
            const date1IST = new Date(date1.getTime() + istOffset);
            const date2IST = new Date(date2.getTime() + istOffset);
            result = date1IST.getTime() >= date2IST.getTime();
          } else {
            result =
              value1 && parseInt(value1) >= parseInt(value) ? true : false;
          }
          break;
        case "<=":
          if (
            condition.data_type == "DATE" &&
            condition.data_source == "TODAY" &&
            condition.result_type != "DATE"
          ) {
            const dateValue1 = new Date(value1);
            const dateValue2 = new Date(value);

            dateValue1.setHours(0, 0, 0, 0);
            dateValue2.setHours(0, 0, 0, 0);
            result = dateValue1.getTime() <= dateValue2.getTime();
          } else if (
            condition.data_type === "DATE" &&
            condition.result_type == "DATE"
          ) {
            const dateValue1InMilliseconds = new Date(value1).getTime();
            const dateValueInMilliseconds = new Date(value).getTime();

            result = dateValue1InMilliseconds <= dateValueInMilliseconds;
          } else if (condition.data_type == "DATE_TIME") {
            const date1 = new Date(value1);
            const date2 = new Date(value);
            const istOffset = 5.5 * 60 * 60 * 1000;
            const date1IST = new Date(date1.getTime() + istOffset);
            const date2IST = new Date(date2.getTime() + istOffset);
            result = date1IST.getTime() <= date2IST.getTime();
          } else {
            result =
              value1 && parseInt(value1) <= parseInt(value) ? true : false;
          }
          break;
        case "=":
          if (
            condition.input_type == "DATE" &&
            condition.data_source == "TODAY" &&
            condition.result_type !== "DATE"
          ) {
            const dateValue1 = new Date(value1);
            const dateValue2 = new Date(value);

            dateValue1.setHours(0, 0, 0, 0);
            dateValue2.setHours(0, 0, 0, 0);
            result = dateValue1.getTime() === dateValue2.getTime();
          } else if (
            condition.data_type == "DATE" &&
            condition.result_type != "DATE" &&
            condition.data_source !== "TEMPLATE"
          ) {
            const dateValue1 = new Date(value1);
            const dateValueInMilliseconds = dateValue1.getTime();
            const dateToCompareInMilliseconds = new Date(value).getTime();
            result = dateValueInMilliseconds == dateToCompareInMilliseconds;
          } else if (
            condition.data_type == "DATE" &&
            condition.result_type != "DATE" &&
            condition.data_source == "TEMPLATE"
          ) {
            const dateValueInMilliseconds = new Date(value1).getTime();
            const dateArray =
              value && typeof value == "string" ? value.split(",") : value;
            result = (dateArray || []).some((dateStr) => {
              const dateToCompareInMilliseconds = new Date(dateStr).getTime();
              return dateToCompareInMilliseconds === dateValueInMilliseconds;
            });
          } else {
            result =
              (value1 || value == false) && value1 == value ? true : false;
          }
          break;

        case "!=":
          result = !(value1 || value == false) || value1 != value;
          break;
        case "=text-includes":
          result = value1 && value1 && value1.includes(value) ? true : false;
          break;
        case "=case-insensitive":
          result =
            value1 &&
            typeof value1 == "string" &&
            value &&
            value1.toLowerCase() == value.toLowerCase()
              ? true
              : false;
          break;
        case "=case-sensitive":
          result = value1 && value1 == value ? true : false;
          break;
        case "notexists":
          result = value1 == undefined || value1 == "" ? true : false;
          break;
        case "exists":
          result = value1 && value1 != undefined && value1 != "" ? true : false;
          break;
        case "in":
          if (condition.input_type == "CHECKBOX_GROUP") {
            result = value && value1 && value.some((v) => value1.includes(v));
          } else if (
            condition.input_type == "ENTITY" &&
            condition.data_type == "OBJECT_ID" &&
            condition.data_source == "TEMPLATE"
          ) {
            result =
              (value1 || value == false) && value1 == value ? true : false;
          } else {
            result =
              value &&
              (typeof value1 === "string" || Array.isArray(value1)) &&
              value.some((v) => {
                if (typeof v === "object") {
                  return JSON.stringify(v) === JSON.stringify(value1);
                } else {
                  return v == value1;
                }
              });
          }
          break;
        case "nin":
          if (condition.data_type === "OBJECT_ID") {
            result = !(
              value &&
              value1 &&
              value.some((v) => value1.includes(v))
            );
          } else if (
            condition.data_type === "IsUserType" ||
            condition.inputType == "LIST"
          ) {
            result = !value;
          } else {
            if (
              Array.isArray(value) ||
              Array.isArray(value1) ||
              value !== value1
            ) {
              if (Array.isArray(value) && Array.isArray(value1)) {
                result = !value.every((v) => value1.includes(v));
              } else {
                result = true;
              }
            } else {
              if (typeof value == "string") {
                value = [value];
              }
              if (typeof value1 == "string") {
                value1 = [value1];
              }
              result = !(value || []).some((v) => value1.includes(v));
            }
          }
          break;

        case "click":
          result = value1;
          break;
        case "!=case-insensitive":
          result =
            value1 &&
            typeof value1 == "string" &&
            value &&
            value1.toLowerCase() == value.toLowerCase()
              ? false
              : true;
          break;
        case "=starts-with":
          result = value1 && value && value1.charAt(0) === value.charAt(0);
          break;

        case "=not-starts-with":
          result = !value1 || !value || value1.charAt(0) !== value.charAt(0);
          break;
        case "=ends-with":
          result = value1 && value && value1.slice(-value.length) === value;
          break;
        case "!ends-with":
          result = !(value1 && value1.slice(-value.length) === value);
          break;

        case "!=case-sensitive":
          result = value1 && value1 == value ? false : true;
          break;
        case "between":
          result =
            parseFloat(start) <= parseFloat(value1) &&
            parseFloat(end) >= parseFloat(value1);
          break;
        case "ranges": {
          if (condition.input_type === "DATE_RANGE") {
            const startDateRange = new Date(value[0]).getTime();
            const endDateRange = new Date(value[1]).getTime();
            const startDateToCheck = new Date(value1[0]).getTime();
            const endDateToCheck = new Date(value1[1]).getTime();

            result =
              startDateToCheck >= startDateRange &&
              endDateToCheck <= endDateRange;
          } else if (Array.isArray(value) && value.length === 2 && value1) {
            const startDate = new Date(value[0]).getTime();
            const endDate = new Date(value[1]).getTime();
            const valueDate = new Date(value1).getTime();
            result = valueDate >= startDate && valueDate <= endDate;
          } else {
            const startDate = new Date(start).getTime();
            const endDate = new Date(end).getTime();
            const valueDate = new Date(value1).getTime();

            result = startDate <= valueDate && endDate >= valueDate;
          }
          break;
        }
      }
      return result;
    },

    comparisonMethod(value, type) {
      if (type === "DATE" && value) {
        return new Date(value).getTime();
      } else if (
        type === "CHECKBOX" ||
        type === "RADIO" ||
        type === "ACTION_BUTTON" ||
        type === "SIGNATURE"
      ) {
        return value ? true : false;
      } else if (type === "NUMBER") {
        const parsedValue = Number(value);
        return isNaN(parsedValue) ? 0 : parsedValue;
      } else if (type === "TIME" && value) {
        const timeRegex = /^(\d{2}):(\d{2}):(\d{2})$/;
        if (timeRegex.test(value)) {
          const [hours, minutes, seconds] = value.split(":");
          const time = new Date();
          time.setHours(Number(hours));
          time.setMinutes(Number(minutes));
          time.setSeconds(Number(seconds));
          time.setMilliseconds(0);
          return time.getTime();
        }
      }

      return value;
    },

    fetchFieldValue(condition, form, fields, checkFromData = false) {
      let field = (fields || []).find((e) => e.key == condition.selectField);
      //    if (condition?.input_type == "LIST") {
      //   // if (form[condition.selectField] && form[condition.selectField].length > 0) {
      //   //   return true;
      //   // }
      //   return  form[condition.selectField].length
      // }
      if (condition?.input_type == "RADIO") {
        if (
          field?.label &&
          ((checkFromData && form[condition.selectField] == field.label) ||
            (!checkFromData && condition.value == true))
        ) {
          return field.label;
        }
        return "";
      } else if (
        condition?.data_source == "VALUE" ||
        condition?.data_source === "TODAY"
      ) {
        if (condition.data_source === "TODAY") {
          return moment().valueOf();
        }
        if (condition.data_type == "DATE" || condition.data_type == "TIME") {
          return new Date(condition.value).getTime();
        } else if (condition.data_type == "DATE_TIME") {
          const dateObject = new Date(condition.value);
          return dateObject.toString();
        }
        if (condition?.data_type == "NUMBER") {
          return parseFloat(condition.value);
        }
        // else if(condition.data_type == "DATE_RANGE") {
        //   condition.value[0]=new Date(condition.value[0])
        //   condition.value[1]=new Date(condition.value[1])
        //   return condition.value
        // }

        //    if (condition.data_type == "DATE_TIME"  ) {

        //   const now = new Date(condition.value); const hours = now.getHours().toString().padStart(2, '0'); const minutes = now.getMinutes().toString().padStart(2, '0'); const seconds = now.getSeconds().toString().padStart(2, '0'); const timeString = `${hours}:${minutes}:${seconds}`; console.log(timeString);
        // }

        return condition.value;
      } else if (condition?.data_source == "TEMPLATE" && form) {
        let key =
          condition.value &&
          typeof condition.value == "object" &&
          condition.value[0]
            ? condition.value[0]
            : condition.value;
        let keyWithName = form[key + "/name"];
        if (keyWithName && !keyWithName.includes("Data ")) {
          return keyWithName;
        }

        if (key && key.includes("#")) {
          key = key.split("#")[1];
        }
        if (condition?.data_type == "NUMBER" && form[key] && field?.decimals) {
          return parseFloat(form[key]).toFixed(field.decimals);
        }
        return form[key];
      } else if (condition?.data_type == "NUMBER") {
        return parseFloat(condition.value);
      } else if (condition?.data_source == "field_option") {
        if (
          condition?.data_type == "ARRAY" ||
          condition?.data_type == "WEEKDAYS"
        ) {
          return typeof condition.value == "object" && condition.value.length
            ? condition.value
            : [condition.value];
        }

        return condition.value;
      }
      // else if (condition?.data_source == 'TODAY') {
      //   console.log("condition",condition)
      //   console.log("moment()",moment().startOf("day"))
      //   return moment().startOf('day')
      // }

      return condition.value;
    },
    getInventoryData(entityData, resourceManagementFilter, form) {
      try {
        let result = 0,
          selectedData = [];
        if (
          resourceManagementFilter?.data_source == "TEMPLATE" &&
          resourceManagementFilter.value_field
        ) {
          resourceManagementFilter.value =
            form[resourceManagementFilter.value_field.split("#")[1]];
        }
        if (
          resourceManagementFilter?.resource_count &&
          resourceManagementFilter.resource_count.includes("#") &&
          resourceManagementFilter.value > 0
        ) {
          resourceManagementFilter.value = parseFloat(
            resourceManagementFilter.value || 0
          );
          let remaining = resourceManagementFilter.value,
            [templateId, fieldKey] =
              resourceManagementFilter.resource_count.split("#"),
            [qTemplateId, qFieldKey] =
              resourceManagementFilter.resource_value_field.split("#"),
            sum = 0;
          entityData.forEach((data) => {
            if (data?.entityData?.[templateId]?.[fieldKey]) {
              if (remaining > 0) {
                let cQty = parseFloat(
                  data?.entityData?.[templateId]?.[fieldKey]
                );
                let cPrice = parseFloat(
                  data?.entityData?.[qTemplateId]?.[qFieldKey]
                );
                if (cQty <= remaining) {
                  sum = sum + cQty * cPrice;
                  selectedData.push({
                    ...data,
                    ...{ quantity: cQty, price: cPrice, completed: true },
                  });
                } else {
                  sum = sum + remaining * cPrice;
                  selectedData.push({
                    ...data,
                    ...{ quantity: remaining, price: cPrice },
                  });
                }
                remaining = remaining - cQty;
              }
            }
          });
          result = sum;
        }
        return { value: result, selectedData: selectedData };
      } catch (e) {
        return { value: 0, selectedData: [] };
      }
    },
    async fetchCompanyUsersTypes() {
      try {
        if (this.getAuthenticatedUser?.current_workspace?.company_id) {
          await this.$store.dispatch("auth/getUserTypeList", {
            companyId: this.getAuthenticatedUser.current_workspace.company_id,
            isMenu: true,
          });
        } else {
          await this.$store.dispatch("auth/getUserTypeList", {
            companyId: this.getAuthenticatedUser.company_id,
            isMenu: true,
          });
        }
        return this.getUserTypeList;
      } catch (e) {
        console.log("Errors:", e);
        return [];
      }
    },
    includeFormbuilders(getAllFormBuildersData, formbuilderList) {
      if (getAllFormBuildersData?.data) {
        formbuilderList = [...formbuilderList, ...getAllFormBuildersData.data];
      }
      return formbuilderList;
    },
    async fetchAllFormbuilders() {
      try {
        let limit = 10,
          page = 1,
          formbuilderList = [];
        // await this.$store.dispatch("formBuilders/fetchAllFormBuilders", {
        //   limit: limit,
        //   page: page,
        // });
        let getAllFormBuildersData = await fetchFormBuildersByPagination({
          limit: limit,
          page: page,
        });
        if (getAllFormBuildersData?.data) {
          let totalPages = getAllFormBuildersData.total_pages;
          formbuilderList = this.includeEntities(
            getAllFormBuildersData,
            formbuilderList
          );
          if (getAllFormBuildersData.has_more) {
            let pageArray = [];
            for (let i = 2; i <= totalPages; i++) {
              pageArray.push(i);
            }
            await Promise.all(
              pageArray.map(async (p) => {
                // await this.$store.dispatch(
                //   "formBuilders/fetchAllFormBuilders",
                //   {
                //     limit: limit,
                //     page: p,
                //   }
                // );
                let getAllFormBuildersData =
                  await fetchFormBuildersByPagination({
                    limit: limit,
                    page: p,
                  });
                if (getAllFormBuildersData?.data) {
                  formbuilderList = this.includeEntities(
                    getAllFormBuildersData,
                    formbuilderList
                  );
                }
              })
            );
          }
        }
        return formbuilderList;
      } catch (e) {
        return [];
      }
    },
    getMenuItem(id) {
      let menuItems = [];
      const menuList = this.getMenu.menu_list || this.getEntityMenu.menu_list;
      menuList.map((menu) => {
        if (!menu.children.length) {
          menuItems.push(menu);
        } else {
          menu.children.map((sub) => {
            if (!sub.children?.length) {
              menuItems.push(sub);
            } else {
              menuItems = [...menuItems, ...sub.children];
            }
          });
        }
      });
      return menuItems.find((menu) => menu._id == id);
    },
    goToMenuItem(data, isChild) {
      if (
        this.$route.name == "ApEntityExecute" ||
        this.$route.name == "ApPreviewEntityData"
      ) {
        this.isApplicationUserSide = true;
      }
      if (this.isApplicationUserSide) {
        let filter;
        if (data?.filters) {
          filter = this.filtersObject[data.filters];
        }
        if (isChild) {
          if (
            data.entity_type === "NESTED_RELATIONAL_ENTITY" &&
            data?.feature === "entities"
          ) {
            let id =
              data.nested_relational_entity &&
              data.nested_relational_entity.includes("#")
                ? data.nested_relational_entity.split("#")[1]
                : data.entity_id && data.entity_id._id
                ? data.entity_id._id
                : data.entity_id;
            if (data.redirectToAdd) {
              this.$router.push({
                path: `/ap/entity-execute/${data.entity_id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&addNew=true&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
                query: {
                  ...{
                    menu_id: this.$route.params.menuId,
                    type: "s7aLF3NnAQg=",
                  },
                },
              });
            } else {
              this.$router.push({
                path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
              });
            }
          } else if (
            data.entity_type === "ENTITY" &&
            data?.feature === "entities"
          ) {
            let id =
              data.entity_id && data.entity_id._id
                ? data.entity_id._id
                : data.entity_id;
            if (data.redirectToAdd) {
              this.$router.push({
                path: `/ap/entity-execute/${data.entity_id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&addNew=true&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
                query: {
                  ...{
                    menu_id: this.$route.params.menuId,
                    type: "s7aLF3NnAQg=",
                  },
                },
              });
            } else {
              this.$router.push({
                path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
              });
            }
          } else if (
            data.feature === "entities" &&
            data.entity_type === "RELATIONAL_ENTITY"
          ) {
            let id =
              data.relational_entity && data.relational_entity._id
                ? data.relational_entity._id
                : data.relational_entity;
            this.$router.push({
              path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                Math.random() * 100000
              )}&filter=${
                data.filters
                  ? data.filters._id
                    ? data.filters._id
                    : data.filters
                  : ""
              }&customization=${
                data.customization ? data.customization : ""
              }&viewType=${filter?.displayType ? filter.displayType : "TABLE"}`,
            });
          } else if (
            data.feature === "page_dashboard" &&
            data.page_dashboard !== this.$route.params.dashboardId
          ) {
            this.$router.push(`/ap/page-dashboard/${data.page_dashboard}`);
          } else if (data.feature === "entity_views") {
            this.$router.push({
              path: `/ap/entity-views/view/${
                data.entityviews_id
              }?routeType=entityviews&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          } else {
            if (data.feature === "dashboard") {
              this.$router.push("/ap/dashboard");
            }
            if (data.feature === "my_documents") {
              this.$router.push("/ap/documents");
            }
            if (data.feature === "my_workflows") {
              this.$router.push("/ap/workflows");
            }
            if (data.feature === "my_form_builders") {
              this.$router.push("/ap/formbuilder");
            }
            if (data.feature === "my_approval_forms") {
              this.$router.push("/ap/approvalforms");
            }
            if (data.feature === "a_form_template") {
              const routePath = `/ap/template/${data.template_id}/menu/view`;
              if (this.$route.path !== routePath) {
                this.$router.push(routePath).catch(() => {});
              }
            }
            if (
              data.feature == "form_builder" &&
              data.formbuilder_id &&
              (data.form_builder_code || data?.formbuilder_id?.code)
            ) {
              this.$router.push({
                path: `/ap/fbd/${
                  data.form_builder_code || data.formbuilder_id.code
                }`,
                query: {
                  key: Math.floor(Math.random() * 100000),
                  menuId: data && data._id ? data._id : "",
                },
              });
            }
          }
        } else {
          if (data.feature === "dashboard") {
            this.$router.push("/ap/dashboard");
          }
          if (data.feature === "my_documents") {
            this.$router.push("/ap/documents");
          }
          if (data.feature === "my_workflows") {
            this.$router.push("/ap/workflows");
          }
          if (data.feature === "my_form_builders") {
            this.$router.push("/ap/formbuilder");
          }
          if (data.feature === "my_approval_forms") {
            this.$router.push("/ap/approvalforms");
          }
          if (data.feature === "a_form_template") {
            const routePath = `/ap/template/${data.template_id}/menu/view`;
            if (this.$route.path !== routePath) {
              this.$router.push(routePath).catch(() => {});
            }
          }
          if (
            data.feature == "form_builder" &&
            data.formbuilder_id &&
            data.form_builder_code
          ) {
            this.$router.push({
              path: `/ap/fbd/${data.form_builder_code}`,
              query: {
                key: Math.floor(Math.random() * 100000),
                menuId: data && data._id ? data._id : "",
              },
            });
          }
          if (
            data.entity_type === "NESTED_RELATIONAL_ENTITY" &&
            data?.feature === "entities"
          ) {
            let id =
              data.nested_relational_entity &&
              data.nested_relational_entity.includes("#")
                ? data.nested_relational_entity.split("#")[1]
                : data.entity_id && data.entity_id._id
                ? data.entity_id._id
                : data.entity_id;
            this.$router.push({
              path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                Math.random() * 100000
              )}&filter=${
                data.filters
                  ? data.filters._id
                    ? data.filters._id
                    : data.filters
                  : ""
              }&customization=${
                data.customization ? data.customization : ""
              }&viewType=${filter?.displayType ? filter.displayType : "TABLE"}`,
            });
          } else if (
            data.feature === "entities" &&
            data.entity_type === "RELATIONAL_ENTITY"
          ) {
            let id =
              data.relational_entity && data.relational_entity._id
                ? data.relational_entity._id
                : data.relational_entity;
            if (data.redirectToAdd) {
              this.$router.push({
                path: `/ap/entity-execute/${data.entity_id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&addNew=true&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
                query: {
                  ...{
                    menu_id: this.$route.params.menuId,
                    type: "s7aLF3NnAQg=",
                  },
                },
              });
            } else {
              this.$router.push({
                path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
              });
            }
          }
          if (data.feature === "entities" && data.entity_type === "ENTITY") {
            let id =
              data.entity_id && data.entity_id._id
                ? data.entity_id._id
                : data.entity_id;
            if (data.redirectToAdd) {
              this.$router.push({
                path: `/ap/entity-execute/${data.entity_id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&addNew=true&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
                query: {
                  ...{
                    menu_id: this.$route.params.menuId,
                    type: "s7aLF3NnAQg=",
                  },
                },
              });
            } else {
              this.$router.push({
                path: `/ap/entity/${id}/${data._id}?key=${Math.floor(
                  Math.random() * 100000
                )}&filter=${
                  data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                }&customization=${
                  data.customization ? data.customization : ""
                }&key=${Math.floor(
                  Math.random() * 100000
                )}&isMenu=true&redirect=${
                  data.redirectMenuItem && data.redirectMenuItem != undefined
                    ? data.redirectMenuItem
                    : ""
                }&viewType=${
                  filter?.displayType ? filter.displayType : "TABLE"
                }`,
              });
            }
          }
          if (data.feature === "entity_views") {
            this.$router.push({
              path: `/ap/entity-views/view/${
                data.entityviews_id
              }?routeType=entityviews&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          }
          if (
            data.feature === "page_dashboard" &&
            data.page_dashboard !== this.$route.params.dashboardId
          ) {
            this.$router.push(`/ap/page-dashboard/${data.page_dashboard}`);
          }
        }
      } else {
        if (data && data.feature === "ENTITIES") {
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${
                  data.groups
                }&routeType=subEntity&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${
                    data.entity_id._id
                  }?routeType=subEntity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&addNew=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }`,
                });
              } else {
                this.$router.push({
                  path: `/entity/${
                    data.entity_id._id
                  }?routeType=subEntity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&key=${Math.floor(Math.random() * 100000)}&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }`,
                });
              }
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${
                  data.groups
                }&routeType=entity&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${
                    data.entity_id
                  }?routeType=entity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&key=${Math.floor(
                    Math.random() * 100000
                  )}&addNew=true&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }`,
                });
              } else {
                this.$router.push({
                  path: `/entity/${data.entity_id}?routeType=entity&filter=${
                    data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                  }&key=${Math.floor(Math.random() * 100000)}&redirect=${
                    data.redirectMenuItem && data.redirectMenuItem != undefined
                      ? data.redirectMenuItem
                      : ""
                  }`,
                });
              }
            }
          }
        } else if (data && data.feature === "FORM_BUILDER") {
          let formBuilderKey = data?.formbuilder_id?.code;
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=subFB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=subFB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=FB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=FB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          }
        } else if (data && data.feature === "WORKFLOWS") {
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          }
        } else if (data && data.feature === "OWNERROUTES") {
          let currentRouteCheck =
            this.$route && this.$route.path && this.$route.path.substring(1);
          if (data.route_id != currentRouteCheck) {
            if (data.route_id === "approval-forms") {
              this.$router.push({
                path: `/forms/${data.route_id}`,
              });
            } else {
              this.$router.push({
                path: `/${data.route_id}`,
              });
            }
          }
        } else if (data && data.feature === "FORM_TEMPLATE") {
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=subFT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=subFT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=FT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=FT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "DOC_TEMPLATE") {
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=subDT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=subDT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=DT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=DT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "ENTITY_VIEWS") {
          this.$router.push({
            path: `/entity-views/view/${
              data.entityviews_id
            }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
          });
        } else if (
          data &&
          data.feature === "PAGE_DASHBOARD" &&
          data.page_dashboard !== this.$route.params.dashboardId
        ) {
          this.$router.push(`/page-dashboard/${data.page_dashboard}`);
        } else {
          this.$router.push({
            path: `/dashboard`,
          });
        }
        this.$store.commit("navigationOpen/setCurrentMenu", data, {
          root: true,
        });
      }
    },
    async fetchIconNames() {
      let icons = await fetchAllIcons();
      return icons.map((e) => e.name);
    },
    getSelectedFieldsForFormula(selectedFields, field, fieldLabelsObject = {}) {
      if (!selectedFields?.length) return [[], ""];
      let exp = "";
      let dataTableParentKey = "";
      if (field.data_table_parent_name) {
        dataTableParentKey = this.makeSlug(field.data_table_parent_name);
      }
      for (let i = 0; i < selectedFields.length; i++) {
        let fd = selectedFields[i];
        let currentField;
        if (fd.type == "FIELD") {
          currentField = fieldLabelsObject[fd.field_name];
          if (currentField?.inputType == "FORMULA") {
            let fieldsToAdd = [
              {
                type: "PARENTHESES",
                parentheses: "(",
              },
              ...currentField.selected_fields,
              {
                type: "PARENTHESES",
                parentheses: ")",
              },
            ];
            selectedFields.splice(i, 1, ...fieldsToAdd);
            exp = exp + "(" + currentField?.formula + ")";
            continue;
          }
        }
        switch (fd.type) {
          case "FIELD":
            fd = {
              ...fd,
              template_name: "Self",
              key: currentField?.key,
              u_key: `self${currentField?.key}`,
              input_type: currentField?.inputType,
              ...(currentField?.currency_type && {
                currency_type: currentField.currency_type,
              }),
              ...(dataTableParentKey && {
                data_table_key: dataTableParentKey,
              }),
            };
            exp += ` ${fd.field_name}`;
            break;
          case "OPERATOR":
            exp += ` ${fd.operator}`;
            break;
          case "PARENTHESES":
            exp += ` ${fd.parentheses}`;
            break;
          case "NUMBER":
            exp += ` ${fd.number}`;
            break;
        }
        selectedFields[i] = fd;
      }
      return [selectedFields, exp];
    },
    makeSlug(text) {
      return text
        .trim()
        .toLowerCase()
        .replace(/[ /&]/g, "_")
        .replace(/[^\w-]+/g, "")
        .replace(/(-)\1+/gi, (str, match) => {
          return match[0];
        });
    },
    getAggregateFunctionType(type) {
      let returnType = type;
      switch (type) {
        case "SUM":
          returnType = "Sum";
          break;
        case "AVERAGE":
          returnType = "Average";
          break;
      }
      return returnType;
    },
  },
};
